import React, { useState, useEffect } from 'react'
import {Link, useHistory} from 'react-router-dom';
import {all_reference} from "../../api/MainAPI";
import Pagination from "./Pagination";
import {Button} from "react-bootstrap";

const References = (props) => {
    const history = useHistory();

    const [category, setCategory] = useState("construction")
    const [references, setReferences] = useState([])
    const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15);

    const [categoryKor, setCategoryKor] = useState("건설업")
    useEffect(() => {
        return history.listen((location => {
            try{
                const _category = location.pathname.split("/")[2];
                setCurrentPage(1)
                setCategory(_category)
            }catch (e){
                setCurrentPage(1)
                setCategory("construction")
            }

        }))
    },[history])
    useEffect(() => {
        try{
            const _category = history.location.pathname.split("/")[2];
            async function get_data(category){
                if (category === "safety"){
                    setCategoryKor("안전진단")
                }else if (category === "manufacture"){
                    setCategoryKor("제조업")
                }
                else{
                    category = "construction";
                    setCategory("construction")
                    setCategoryKor("건설업")
                }

                const response = await all_reference(category)
                setCurrentPage(1)
                if (response.status === 200){
                    const datas = await response.data
                    if (datas['data']) {
                        setReferences(datas['data'])
                    }
                }

            }
            get_data(_category)
        }catch (e){
            setCurrentPage(1)
            setCategory("construction")
        }



    }, [category])
    const handleRefernece = (id) => {
        history.push("/reference/" + id)
    }
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = references.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">자료실</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><a>홈</a></li>
            <li><a>자료실</a></li>
            <li><a>{categoryKor}</a></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox reperence">
          <div className="tablebox">
            <table className="h_table textcenter" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th className="min">구분</th>
                  <th>제목</th>
                  <th className="min">게시일</th>
                  <th className="min">작성자</th>
                </tr>
              </thead>
              <tbody>
              {currentPosts.length === 0 ? <tr><td colSpan="4">등록된 자료가 없습니다.</td></tr>: currentPosts.map((reference, idx) => {
                  return (
                      <tr key={idx} onClick={() => handleRefernece(reference.id)}>
                          <td className={reference.notice ? "notice" : ""}>{reference.notice ? "공지" : "일반"}</td>
                          <td>{reference.title}</td>
                          <td>{reference.date.slice(0, 10)}</td>
                          <td>{reference.writer}</td>
                      </tr>
                  )
              })}

              </tbody>
            </table>
          </div>
                <Pagination currentPage={currentPage} postsPerPage={postsPerPage} totalPosts={references.length} paginate={paginate}/>

        </div>

      </div>
    )
}
export default References