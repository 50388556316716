import React from 'react'

import img1 from '../../../assets/img/business/part_2_con1_01.gif';
import img2 from '../../../assets/img/business/part_2_con1_02.gif';
import img3 from '../../../assets/img/business/part_2_con2.gif';
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const ConstructionSafety = () => {
  //건설현장 안전컨설팅
    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>건설현장 안전컨설팅</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">건설현장 안전컨설팅</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
          <table border="0" cellSpacing="0" cellPadding="0" width="700">
              <tbody>
              <tr>
                  <td width="700">
                      <table border="0" cellSpacing="0" cellPadding="0" width="700">
                          <tbody>
                          <tr>
                              <td valign="top" width="650">
                                  <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                      <tbody>
                                      <tr>
                                          <td height="25" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                                              color="#64700d"><strong>종합안전컨설팅 이란?</strong></font></td>
                                      </tr>
                                      <tr>
                                          <td height="10" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650">
                                              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                  <tbody>
                                                  <tr>
                                                      <td background="../images/guide/guide01_19.gif" width="653"
                                                          align="center">
                                                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                              <tbody>
                                                              <tr>
                                                                  <td>한국재해예방관리원은 하나의 생명이 태어나듯 건설되어지는 모든 시설물에 대한
                                                                      계획·설계·시공· 유지관리 단계에까지 종합적이고 일원화된 관리 형태의 하나로서 태어난
                                                                      생명물이 안전하고 건실하게 유지될수 있도록 평생 서비스 개념을 최우선으로 20여년간의
                                                                      축적된 안전에 관한 Know-How를 종합하여 기업과 회원에 대해 최상의 종합안전 서비스를
                                                                      제공하고 풍부한 현장경험과 전문지식을 갖춘 전문 기술자들이 고객의 요구에 맞는 질높은
                                                                      안전과 안전에 관련한 모든사항에 대한 해결방안을 제시 하는 시스템입니다.<br/><br/>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center"><img
                                                                      src={img1}
                                                                      width="610" height="327" alt=""/></td>
                                                              </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td height="50" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                                              color="#64700d"><strong>건설안전 컨설팅의 추진배경</strong></font></td>
                                      </tr>
                                      <tr>
                                          <td height="10" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650">
                                              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                  <tbody>
                                                  <tr>
                                                      <td background="../images/guide/guide01_19.gif" width="653"
                                                          align="center">
                                                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                              <tbody>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="140"
                                                                                  align="center"><strong>배 경</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 경기침체 및 IMF 체제 →
                                                                                  기업구조조정으로 안전부서 축소<br/>- 안전담당 인력의 감축 →
                                                                                  전국 현장에 대한 안전통제 미흡<br/>- 객관적인 현장평가 →
                                                                                  외부기관의 객관적 평가필요<br/>- 안전관리의 수준향상 → 자체적인
                                                                                  수준향상에 한계<br/>- 공사수주에 안전 영향 → P · Q,
                                                                                  영업정지 등에 안전반영<br/>- 기업이미지 제고 향상 → 대형사고의
                                                                                  매스컴 전송 확산 <br/><br/>■ 안전관리가 건설기업의
                                                                                  생존혁신전략으로 등장
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="70"
                                                                                  align="center"><strong>필 요 성</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">소수의 안전담당 인력으로 효율적인
                                                                                  현장 안전관리를 통제 할 수 없어 <br/>외부전문기관에 안전분야를
                                                                                  아웃소싱하여 실 시간 안전통제 시스템을 <br/>가동함으로써
                                                                                  산업재해를 예방하고 기업발전의 향상에 기여할 필요
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td><br/>상기의 사회적 여건에 부흥하고 기업 안전수준향상을 통한 건설재해를 예방하기 위하여
                                                                      건설안전 컨설팅시스템을 개발하여 제안<br/><br/></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center"><img
                                                                      src={img2}
                                                                      width="610" height="418" alt=""/></td>
                                                              </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td height="50" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                                              color="#64700d"><strong>건설안전 컨설팅의 목적</strong></font></td>
                                      </tr>
                                      <tr>
                                          <td height="10" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650">
                                              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                  <tbody>
                                                  <tr>
                                                      <td background="../images/guide/guide01_19.gif" width="653"
                                                          align="center">
                                                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                              <tbody>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>업 무 내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="100"
                                                                                  align="center"><strong>기본사항</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 안전보건관리 조직 및 그
                                                                                  직무수행의 적정성<br/>- 안전보건교육 실시 및 개인보호구
                                                                                  지급.착용의 적정성<br/>- 산업안전보건관리비 계상ㆍ사용의
                                                                                  적정성<br/>- 안전점검, 합동점검, 자체검사, 작업환경측정 실시의
                                                                                  적정성<br/>- 협의체회의, 산업안전보건위원회,
                                                                                  건강진단,무재해운동의 적정성
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="50"
                                                                                  align="center"><strong>특별사항</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 각종 안전관리계획서의 검토 ·
                                                                                  심사 · 작성지원등 관리적 시스템에 관련된 <br/>모든사항(구체적인
                                                                                  특별사항은 협의결정)
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td><br/></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center">
                                                                      <div><img
                                                                          src={img3}
                                                                          width="610" height="532" alt=""/><br/><strong><br/>※
                                                                          업무수행은 점검일수, 점검내용, 현장규모, 본사지침등에 따라 변경될 수
                                                                          있음.</strong></div>
                                                                  </td>
                                                              </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td height="50" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                                              color="#64700d"><strong>건설안전 컨설팅의 업무영역</strong></font></td>
                                      </tr>
                                      <tr>
                                          <td height="10" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650">
                                              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                  <tbody>
                                                  <tr>
                                                      <td background="../images/guide/guide01_19.gif" width="653"
                                                          align="center">
                                                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                              <tbody>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 현장
                                                                      안전점검</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>점검일수</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 1일~ 3일(필요일수 -
                                                                                  협의사항)
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>점 검 자</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 건설안전 기술사 1인 - 건설안전
                                                                                  중급기술자 1인
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="80"
                                                                                  align="center"><strong>점검장비</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 디지털카메라<br/>-
                                                                                  켐코더<br/>- 현장별 필요 각종 측정장비<br/>- 자, 렌턴
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="100"
                                                                                  align="center"><strong>점검내용</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 근로자
                                                                                  안전관련사항(협의사항)<br/>- 추락,낙하,붕괴,감전,협착
                                                                                  등<br/>- 리프트, T/C 등 건설기계<br/>- 용접기 등
                                                                                  위험기계기구<br/>- 정리정돈, 안전통로 확보 등
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 근로자 안전보건 교육</font></strong>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>교육대상</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 현장내 전 근로자</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>교육강사</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 점검팀원 : 중급이상 기술자
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="80"
                                                                                  align="center"><strong>교육장비</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 빔-프로젝터<br/>-
                                                                                  켐코더<br/>- 디지털-카메라<br/>- 노트북-컴퓨터
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="80"
                                                                                  align="center"><strong>교육내용</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 안전점검시 캠코더로 촬영된
                                                                                  불안전행동 및 불안전작업방법<br/>- 올바른 작업방법 및
                                                                                  순서<br/>- 개인보호구 착용<br/>- 안전의 지식,기능,태도교육
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 관리감독자 안전보건 교육 및
                                                                      강평</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>교육대상</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 현장내 전 관리감독자</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>교육강사</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 점검팀장 : 건설안전기술사
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="80"
                                                                                  align="center"><strong>교육장비</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 빔-프로젝터<br/>-
                                                                                  켐코더<br/>- 디지털-카메라<br/>- 노트북-컴퓨터
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="100"
                                                                                  align="center"><strong>교육내용</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 점검 지적사항 및 근본원인
                                                                                  발굴<br/>- 위험사항의 개선방법 · 대안제시<br/>- 타사 또는
                                                                                  타현장 개선사례 소개<br/>- 시스템 개선 및 조직관리<br/>-
                                                                                  향후 작업시 안전 유의사항
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 보고서 작성 및 보고</font></strong>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>작성시기</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 점검당일 작성(필요시 종합보고서
                                                                                  · 작성)
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>제출보고</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 현장 1부, 본사 E-mail
                                                                                  전송
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="50"
                                                                                  align="center"><strong>작성장비</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 디지털-카메라<br/>-
                                                                                  노트북-컴퓨터<br/>- 칼라-프린터
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="100"
                                                                                  align="center"><strong>작성내용</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 갑지 , 공사개요 · 현황,
                                                                                  재해,우수<br/>- 관리 사항:현황,지적,개선방법<br/>- 기술
                                                                                  사항:지적,원인,개선방법<br/>- 교육 사항:근로자,관리자
                                                                                  교육<br/>- 총평:조직,향후사항,총평,평가
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 점검자
                                                                      회의</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="30%" align="center"><strong>구
                                                                                  분</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>내 용</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>회의시기</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 매월 ( 분기 · 반기, 년간
                                                                                  실시)
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center"><strong>회의자료</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 현장 점검결과 월말보고서</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="40"
                                                                                  align="center"><strong>참 석 자</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 건설회사 : 안전부서장 및
                                                                                  직원<br/>- 점검기관 : 점검팀장
                                                                              </td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="110"
                                                                                  align="center"><strong>회의내용</strong>
                                                                              </td>
                                                                              <td bgcolor="#ffffff">- 월간 점검실시 현황<br/>-
                                                                                  현장별 주요 문제점 및 개선안<br/>- 점검 시스템의 문제 및
                                                                                  개선안<br/>- 타 회사의 안전우수사항 · 제도<br/>- 안전제안
                                                                                  및 현안문제 토의<br/>- 익월 점검 시 중점 및 유의사항
                                                                              </td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td height="50" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td><strong><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                                              color="#64700d"><strong>컨설팅 비용처리</strong></font></strong></td>
                                      </tr>
                                      <tr>
                                          <td height="10" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650">
                                              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                  <tbody>
                                                  <tr>
                                                      <td background="../images/guide/guide01_19.gif" width="653"
                                                          align="center">
                                                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                              <tbody>
                                                              <tr>
                                                                  <td>노동부 고시 별표2, 4항목(사업장의 안전진단비등)으로 집행가능</td>
                                                              </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td height="50" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td><strong><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                                              color="#64700d"><strong>컨설팅 협약업체</strong></font></strong></td>
                                      </tr>
                                      <tr>
                                          <td height="10" width="650"></td>
                                      </tr>
                                      <tr>
                                          <td width="650">
                                              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                  <tbody>
                                                  <tr>
                                                      <td background="../images/guide/guide01_19.gif" width="650"
                                                          align="center">
                                                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                                              <tbody>
                                                              <tr>
                                                                  <td bgcolor="#e6e6e6">
                                                                      <table border="0" cellSpacing="1" cellPadding="0"
                                                                             width="100%">
                                                                          <tbody>
                                                                          <tr>
                                                                              <td bgcolor="#eeedeb" height="30"
                                                                                  width="40%" align="center">
                                                                                  <strong>회사명</strong></td>
                                                                              <td bgcolor="#eeedeb" align="center">
                                                                                  <strong>용역명</strong></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center">
                                                                                  <strong>한영산업개발(주)</strong></td>
                                                                              <td bgcolor="#ffffff">건설안전컨설팅 업무수행</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center">
                                                                                  <strong>믿음종합건설(주)</strong></td>
                                                                              <td bgcolor="#ffffff">건설안전컨설팅 업무수행</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center">
                                                                                  <strong>한덕산업개발(주)</strong></td>
                                                                              <td bgcolor="#ffffff">건설안전컨설팅 업무수행</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td bgcolor="#f7f5f4" height="30"
                                                                                  align="center">
                                                                                  <strong>동해건설(주)</strong></td>
                                                                              <td bgcolor="#ffffff">건설안전컨설팅 업무수행</td>
                                                                          </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 대
                                                                      상</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>- 공공 관리주체<br/>- 초일류기업을 지향하는 모든 건설회사<br/>- 안전 및 안전에
                                                                      관련된 일원화된 관리가 요구되는 기업
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 계획/설계부문 -
                                                                      설계회사/건설엔지니어링</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>- 안전설계의 계획<br/>- 안전설계의 자문<br/>- 안전설계의 심의<br/>- 안전에
                                                                      관련한 감리
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 시공부문 - 건설회사</font></strong>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>- 착공 전 건설공사 유해위험방지 계획서 작성<br/>- 착공 전 건설공사 안전관리계획서
                                                                      작성<br/>- 착공 전 설계(구조)부문에 대한 설계검토 수행<br/>- 시공 중
                                                                      타워크레인, 건설용 리프트등 자체검사 및 대행<br/>- 시공 중 안전에 관련한 위험적요소
                                                                      및 안전대책 기술적 사항 제공<br/>- 시공 중 건설재해예방 안전컨설팅 수행<br/>-
                                                                      시공 중 건설기술관리법에 의한 정기안전점검 수행<br/>- 시공 중 산업안전보건법에 의한
                                                                      유해· 위험 기계 기구에 대한 자체검사<br/>- 시공 중 발생한 결함 및 문제점에 대한
                                                                      안전성검토 수행<br/>- 준공직 전 건설기술관리법에 의한 초기점검 수행<br/>- 준공직
                                                                      전 건설기술관리법에 의한 종합보고서 제출
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙ 유지관리부문 -
                                                                      공공관리주체</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>- 시설물 유지관리 계획서 작성 제출<br/>- 시설물 안전관리에 관한 특별법에 의한
                                                                      정기점검 수행<br/>- 시설물 안전관리에 관한 특별법에 의한 정밀점검 수행<br/>-
                                                                      시설물 안전관리에 관한 특별법에 의한 정밀안전진단수행<br/>- 산업안전보건법에 의한
                                                                      안전관리업무 대행 수행<br/>- 기타 제조/ 전기 및 정보통신 산업현장과 시설물의
                                                                      잠재위험에 대한 가스, 소방, 환경등 종합안전진단 수행
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>&nbsp;</td>
                                                              </tr>
                                                              <tr>
                                                                  <td><strong><font color="#3298a4">⊙
                                                                      기대효과</font></strong></td>
                                                              </tr>
                                                              <tr>
                                                                  <td height="7" width="650"></td>
                                                              </tr>
                                                              <tr>
                                                                  <td>- 관리주체/기업/건설현장에 대한 최고의 안전기술 서비스 제공<br/>-
                                                                      관리주체/기업/건설현장에 대한 법률정보 및 기술정보 제공<br/>-
                                                                      관리주체/기업/건설현장의 체계적인 안전관리 시스템 제공<br/>- 관리주체/기업/건설현장의
                                                                      일원화된 안전관리<br/>- 관리주체/기업/건설현장의 안전관리수준 평가 자료 제공<br/>-
                                                                      관리주체/기업/건설현장의 안전관리 경비 절감의 극대화<br/>- 관리주체/기업/건설현장의
                                                                      안전관리 수준 평가에 대한 공정성 제공
                                                                  </td>
                                                              </tr>
                                                              </tbody>
                                                          </table>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td height="20" width="700"></td>
              </tr>
              </tbody>
          </table>
      </div>
    </div>
            </div>
        </div>
        </div>
  )
}
export default ConstructionSafety
