import React, {useState, useEffect} from 'react';
import axios from 'axios'
import {Link, useHistory} from "react-router-dom";
import Pagination from "./Pagination";
import {Button, Col, Container, Row} from "react-bootstrap";
const ReferenceView = ({match}) => {
const server = "http://18.179.138.213:3001"
    const [codes, setCodes] = useState("")
    const [title, setTitle] = useState("")
    const [writer, setWriter] = useState("")
    const [category, setCategory] = useState("")
    const [created_at, setCreated_at] = useState("")
    const [files, setFiles] = useState([])
    const [orgFiles, setOrgFiles] = useState([])
    let id = match.params.id
    const history = useHistory();
    useEffect(() => {

       axios.get(server + "/reference-view/" + id, {
        headers: { 'Content-Type' : 'application/json'}
       }).then((response) => {
          try{
            let datas = response.data
            if(datas.code == 200) {

              setTitle(datas['data']['title'])
              setWriter(datas['data']['writer'])
              setCreated_at(datas['data']['date'])
              setFiles(datas['data']['files'].split(","))
              setOrgFiles(datas['data']['orgFiles'].split(","))
              setCodes(datas['data']['content'])
                setCategory(datas['data']['category'])
                if (datas['data']['category'] === "construction") {
                    setCategory("건설업")
                }else if (datas['data']['category'] === "manufacture") {
                    setCategory("제조업")
                }else {
                    setCategory("안전진단")
                }
            }else {
                alert("게시글을 불러오는데 실패하였습니다.!!!!")
                //window.location.replace("/references/construction")
            }


          }catch (e) {
            alert("게시글을 불러오는데 실패하였습니다.")
            //window.location.replace("/references/construction")
          }

        })
      }, [])
    const createMarkup = () => {

        return {__html: codes};
      }
    const handleMenu = () => {
        if (category === "건설업") {
            return history.push("/references/construction")
        }else if (category === "제조업") {
            return history.push("/references/manufacture")
        }else{
            return history.push("/references/safety")
        }
    }
      return (
          <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">자료실</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/references/construction">자료실</Link></li>
            <li><a>{category}</a></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox reperence">
            <Container>
                <Row>
                    <Col>
                        <h4 style={{fontWeight : "600"}}>{title}</h4>

                    </Col>
                </Row>
                <hr/>
                <Row>

                    <Col sm={2}>
                        <h5>작성자</h5>
                    </Col>

                    <Col sm={4}>
                        <h5>{writer}</h5>
                    </Col>
                    <Col sm={2}>
                        <h5>작성일</h5>
                    </Col>

                    <Col sm={4}>
                        <h5>{created_at}</h5>
                    </Col>

                </Row>
                <hr/>
                <Row>
                    <Col sm={2}>
                        <h5>첨부파일</h5>
                    </Col>
                    <Col>
                        <div style={{overflow : "auto"}}>
                                { files.length == 0 ? <h5>등록된 파일이 없습니다.</h5>
                                : files.map((file, index) => {
                                    return <div key={index}><i className="fas fa-save"></i><a href={file} target="_blank"> {orgFiles[index]} <br/></a></div>
                                    })}
                            </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <div style={{overflow : "auto", padding : "10px"}} dangerouslySetInnerHTML={createMarkup()}></div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="d-flex" style={{justifyContent : "space-between"}}>
                        <Button size="sm" variant="secondary" onClick={handleMenu}>목록으로</Button>
                        <Button size="sm" variant="secondary" onClick={() => {history.push("/admin/editreference/" + id)}}>수정</Button>
                    </Col>
                </Row>
            </Container>
        </div>
      </div>
      )
}
export default ReferenceView