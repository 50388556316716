import React from 'react'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";

const ConstructionHazard = () => {
  //건설업 유해위험방지계획서
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>건설업 유해위험방지계획서</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">건설업 유해위험방지계획서</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8" >

        </div>
        <h4>대상사업장</h4>
      <hr className="border-gray-700"/>
          <h5>1. 지상높이가 31m 이상인 건축물 또는 인공구조물, 연면적 30,000㎡ 이상인 건축물 또는 연면적 5,000㎡ 이상의 문화 및 집회시설(전시장 및 동물원 · 식물원은 제외한다), 판매시설,
            운수시설(고속철도의 역사 및 집배송시설은 제외한다), 종교시설, 의료시설 중 종합병원, 숙박시설 중 관광숙박시설, 지하도 상가 또는 냉동 · 냉장창고시설의 건설 · 개조 또는 해체(이하
            "건설등"이라 한다.)
          </h5>
          <h5>2. 연면적 5,000㎡ 이상의 냉동 · 냉장창고시설의 설비공사 및 단열공사</h5>
          <h5>3. 최대 지간길이가 50m 이상인 교량건설 등 공사</h5>
          <h5>4. 터널 건설등의 공사</h5>
          <h5>5. 다목적댐, 발전용댐 및 저수용량 2천만톤 이상의 용수 전용댐, 지방상수도 전용 댐 건설 등의 공사</h5>
          <h5>6. 깊이 10m 이상인 굴착공사</h5>
      <hr className="border-gray-700"/>
        <h4 className="h2-tit">공사 구분</h4>
      <hr className="border-gray-700"/>
          <h5>1종 공사</h5>
              <h5>지상높이 200m 이상 건축물ㆍ인공구조물 건설ㆍ개조ㆍ해체</h5>
              <h5>최대 지간길이 100m 이상인 교량 건설공사</h5>
              <h5>지하철공사, 해·하저 터널공사 및 연장 3km 이상 터널 건설공사</h5>
              <h5>깊이 30m 이상 굴착공사</h5>

      <h5>2종 공사</h5>

              <h5>유해ㆍ위험방지계획서 제출 대상공사 중 1종 공사 제외</h5>
        <hr className="border-gray-700"/>
        <h4 className="h2-tit">제출기한</h4>
      <hr className="border-gray-700"/>
      <h5>일정한 자격을 갖춘자의 검토를 거쳐 공사착공(실착공 기준) 전일까지 공단에 제출</h5>

              <h5>일정한 자격을 갖춘자<br/>- 건설안전분야 산업안전지도사<br/>- 건설안전기술사 또는 토목/건축분야 기술사<br/>- 건설안전산업기사 이상으로서 건설안전관련 실무경력 7년(기사는
                5년) 이상인 자
              </h5>

      <hr className="border-gray-700"/>

        <h4 className="h2-tit">심사절차</h4>
<hr className="border-gray-700"/>
        <h5>※ 1종 공사는 공단 본부에서 심사</h5>
        <h5>※ 계획서 제출은 1,2종 공사 구분 없이 관할 지역본부 · 지도원에 제출</h5>
      <hr className="border-gray-700"/>
        <h4 className="h2-tit mgt30">확인절차</h4>
<hr className="border-gray-700"/>

        <div className="imgbox">
          <div id="Screening-check">
           </div>
          <div className="table_list">
            <table className="business_table2" summary="유해위험 방지 심사 수수료">


              <thead>
              <tr>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}} colSpan="2" scope="col">심사대상</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}} className="last" rowSpan="2" scope="col">수수료<br/>(원)</th>
              </tr>
              <tr>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}} scope="col">종류</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}} scope="col">규모</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className="left" rowSpan="3">1. 지상높이가 31m 이상인 건축물 또는 인공구조물<br/>2. 연면적 30,000㎡ 이상인 건축물<br/>3. 연면적
                  5,000㎡ 이상의 문화 및 집회시설(전시장 및 동물원ㆍ식물원을 제외한다)<br/>ㆍ판매 및 영업시설ㆍ의료시설 중 종합병원ㆍ숙박시설중 관광숙박시설 · 지하도 상가 또는 냉동 ·
                  냉장창고시설<br/>4. 연면적 5,000㎡이상의 냉동 · 냉장창고시설의 설비공사 및 단열공사
                </td>
                <td>가. 대상건축물ㆍ공작물의 수<br/>→ 5개소 미만</td>
                <td className="last">
                  <div>44,000</div>
                </td>
              </tr>
              <tr>
                <td>나. 대상건축물ㆍ공작물의 수<br/>→ 10개소 미만</td>
                <td className="last">
                  <div>58,000</div>
                </td>
              </tr>
              <tr>
                <td>다. 대상건축물ㆍ공작물의 수<br/>→ 10개소 이상</td>
                <td className="last">
                  <div>67,000</div>
                </td>
              </tr>
              <tr>
                <td className="left">○ 최대지간 길이가 50m 이상인 교량 건설 등 공사</td>
                <td>대상 교량 1개소</td>
                <td className="last">
                  <div>44,000</div>
                </td>
              </tr>
              <tr>
                <td className="left" rowSpan="3">○ 터널건설 등의 공사</td>
                <td>가. 길이 50미터 미만</td>
                <td className="last">
                  <div>44,000</div>
                </td>
              </tr>
              <tr>
                <td>나. 길이 500미터 미만</td>
                <td className="last">
                  <div>58,000</div>
                </td>
              </tr>
              <tr>
                <td>다. 길이 500미터 이상</td>
                <td className="last">
                  <div>67,000</div>
                </td>
              </tr>
              <tr>
                <td className="left">○ 다목적댐ㆍ발전용 댐 및 저수용량 2천만톤 이상의 용수 전용댐ㆍ<br/>지방상수도 전용댐 건설 등의 공사</td>
                <td>대상댐 1개소</td>
                <td className="last">
                  <div>58,000</div>
                </td>
              </tr>
              <tr>
                <td className="left" rowSpan="3">○ 굴착깊이가 10m 이상인 굴착공사</td>
                <td>가. 굴착공사 5개소 미만</td>
                <td className="last">
                  <div>44,000</div>
                </td>
              </tr>
              <tr>
                <td>나. 굴착공사 10개소 미만</td>
                <td className="last">
                  <div>58,000</div>
                </td>
              </tr>
              <tr>
                <td>다. 굴착공사 10개소 이상</td>
                <td className="last">
                  <div>67,000</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
            </div>
      </div>
      </div>
  )
}
export default ConstructionHazard
