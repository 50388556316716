import React from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";

const CompleteContractForm = (props) => {
    const {handleAddList, handleRemove, handleCompleteDate, handleTeach, handleCompleteRemark,
    completedate, completeremark, teach, teachdates, handleCompletePrint, handleCompleteSave} = props

    return (
        <Card.Body>
          <div>
                  <Button
                      size="sm"
                    variant="danger"
                    onClick={handleCompletePrint}
                      style={{marginRight : "5px"}}
                  >계약서 출력</Button>
                  <Button
                      size="sm"
                      variant="primary"
                      onClick={handleCompleteSave}
                  >완료증명서 저장</Button>
                </div>
                <Form>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>완료증명서발행일자*</label>
                        <Form.Control
                          type="date"
                          placeholder="2021-01-01"
                          name="completedate"
                          value={completedate}
                          onChange={handleCompleteDate}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>지도일자*</label>
                        <Form.Control
                          type="date"
                          placeholder="2021-01-01"
                          name="teach"
                          value={teach}
                          onChange={handleTeach}
                        ></Form.Control>
                          <Button size="sm" onClick={handleAddList}
                    variant="primary" style={{marginTop : "10px"}}>추가</Button>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>지도일자</label>
                        <div style={{display : "flex", width : "100%", border : "1px solid #E3E3E3", height : "100px"}}>
                            {teachdates.length > 0 ? teachdates.map((value, idx) => {
                                return <div style={{display : "flex"}}key={idx}><p>{idx+1}회차 : {value}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash"
                   viewBox="0 0 16 16" onClick={() => handleRemove(idx)}>
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg></div>
                                })
                            : <span>등록된 지도일자가 없습니다.</span>}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label>비고</label>
                        <Form.Control
                          cols="80"
                          placeholder="비고를 입력해주십시오."
                          rows="4"
                          as="textarea"
                          name="completeremark"
                            value={completeremark}
                            onChange={handleCompleteRemark}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
    )
}
export default CompleteContractForm