import React, {useEffect, useState} from 'react';
import '../../assets/css/auth.css'
import {Link, useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import axios from "axios";
const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [checkID, setCheckID] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['rememberEmail']);
    const history = useHistory();
  useEffect(() => {

      if(cookies.rememberEmail !== undefined) {
        setEmail(cookies.rememberEmail);
        setCheckID(true);
      }
   }, []);

  const handleLogin = async (e) => {
      e.preventDefault();
    if (email === "" || password === "") { return alert("회원 정보를 입력하시오.")}
    if(checkID){
      setCookie('rememberEmail', email, {maxAge: 2000});
    } else {
      removeCookie('rememberEmail');
    }
    try {
      let data = JSON.stringify({"userEmail" : email, "password" : password})
      await axios.post(
         'http://18.179.138.213:3001/login', data, {headers: {
          'Content-Type': 'application/json'
          }}
      ).then((response) => {
        if (response.data.code == 200) {
          doSignUp(response.data['data'])
          history.push("/admin")
        } else if (response.data.code == 401) {
          alert("승인 대기 중 입니다");
        } else if (response.data.code == 404) {
          alert("올바르지 않은 회원정보입니다.");
        }
      }).catch(res => {
        if (res.response.status == 400) {
          alert("올바르지 않은 회원정보입니다.");
        }
      })
    } catch (e) {
      alert("로그인 실패, 담담자에게 문의하세요")
      setEmail("")
      setPassword("")
    }
  }

  const doSignUp = (token) => {
    sessionStorage.setItem('token', token)
  }
  const handleOnChange = (e) => {
    setCheckID(e.target.checked);

  }
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }
  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

    return (
        <div className="login_div">
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <h2 className="active"> 로그인 </h2>
                    <Link to="/register"><h2 className="inactive underlineHover">회원가입 </h2></Link>
                    <form>
                        <input type="text" id="login" className="fadeIn second" name="login" placeholder="이메일"
                            value={email} onChange={onChangeEmail}
                        />
                            <input type="password" id="password" className="fadeIn third" name="login"
                                   placeholder="비밀번호" value={password} onChange={onChangePassword}/>
                                <input type="submit" className="fadeIn fourth" value="로그인" onClick={handleLogin}/>
                                </form>

                    <div id="formFooter">
                        <input
                        type="checkbox"
                        className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        checked={checkID || false} onChange={handleOnChange}
                      /><span className="underlineHover">아이디저장</span>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Login;
