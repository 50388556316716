import axios from 'axios'

const server = "http://18.179.138.213:3001"

const token = sessionStorage.getItem('token')
const user_id = sessionStorage.getItem('id')
const usernumber = sessionStorage.getItem('usernumber')



const save_complete_contract = async (contract_id, date, lead_date, check_update, remark) => {
  let data = JSON.stringify({token : token, user_id : user_id, contract_id : contract_id, date : date, lead_date : lead_date, check_update : check_update, remark : remark})
  return await axios.post(server + "/completemake", data)
}

const print_contract = async (contract_id) => {
  let data = JSON.stringify({token : token, user_id : user_id, contract_id : contract_id})

  return await axios.post(server + "/printcontract", data)
}

const create_contract = async (datas) => {
  return await axios.post(server + "/createcontract", datas)
}


export default {
  save_complete_contract,
  print_contract,
  create_contract
}
