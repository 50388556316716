import React from 'react'
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import {Link} from "react-router-dom";

const MapExampleScript = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 37.3011559, lng: 126.8133066 }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      <Marker position={{ lat: 37.3011559, lng: 126.8133066 }} />
    </GoogleMap>
  ))
);

function GoolgeMaps() {
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">찾아오시는길</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/intro">회사소개</Link></li>
            <li><Link to="/intro/maps">찾아오시는길</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox greeting"  style={{marginTop : "30px"}}>
            <div>
            <MapExampleScript
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC8KFkumPGaod6BXQ3cBj0xO7f54ZUbYoc"
              loadingElement={<div className="h-full" />}
              containerElement={<div className="relative w-full rounded h-600-px" />}
              mapElement={<div className="rounded h-full" />}
            />
          </div>
        </div>
      </div>
  );
}

export default GoolgeMaps