import React from 'react'
import img from '../../../assets/img/business/part_4_con.gif'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const Musculoskeletal = () => {
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>근골격계 유해요인 조사</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">근골격계 유해요인 조사</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
        <table border="0" cellSpacing="0" cellPadding="0" width="700">
          <tbody>
          <tr>
            <td width="700">
              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                <tbody>
                <tr>
                  <td valign="top" width="700">
                    <table border="0" cellSpacing="0" cellPadding="0" width="700">
                      <tbody>
                      <tr>
                        <td height="25" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>제도 및 관련법규</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td><font color="#000000"><strong>○관련법규 </strong></font><br/>산업안전보건법 제24조「보건상의 조치」,
                                      산업보건기준에 관한 규칙 제143조「유해요인 조사」, 근골격계부담작업의 범위(노동부고시 제2003-24호) 총 11가지 부담작업에 의거 사업주는
                                      근골격계부담작업에 근로자를 종사하도록 하는 경우 아래와 같은 주기에 따라 근골격계 유해요인 조사를 실시하여야
                                      합니다. <br/><strong><font color="#000000"><br/>○유해요인 조사 시기</font></strong> <br/>- 정기
                                      조사 : 매 3년 이내<br/>- 수시 조사 : 질환자 발생, 새로운 작업·설비 도입, 작업환경 변경시<br/>- 신설 사업장 : 신설일부터 1년
                                      이내 <br/><br/><font color="#000000"><strong>○위반시 벌칙</strong></font> <br/>- 5년 이하의
                                      징역 또는 5천만원 이하의 벌금(법 제67조) <br/></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="50" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>적용대상ㆍ업종</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td>
                                      <div>법 제24조의 규정에 의한 사업주의 근골격계질환 예방의무는 법 제3조 및 동법시행령 제2조의2(별표 1)에 의하여 근로자를 사용하는 모든
                                        사업 또는 사업장(국가·지방자치단체 및 정부투자기관 포함)에 적용되므로 규모, 업종에 따른 적용 제외 규정 없음<br/></div>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="50" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>업무절차</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td align="center"><img src={img}
                                                            width="610" height="283" alt=""/></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="50" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>유해요인조사 대행효과</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td width="700">- 근골격계질환 유해요인 조사·서류 작성 후 책자 제본을 제공하므로써 담당자 편의 및 시간 절약 <br/>- 근골격계질환
                                      발생 및 근골격계부담작업의 유해요인을 파악하여 인간공학적 개선 대책을 제시함으로써 사업장의 근원적인 예방대책 수립을 지원함으로 자율적인 예방활동
                                      조성<br/>- 유해요인조사 내실화를 위한 실무교육 실시<br/>- 근골격계질환 다발 유해ㆍ위험공정에 대하여 작업관리, 작업방법, 작업환경 등에
                                      대한 종합적인 근골격계질환 예방 기술지원 실시 <br/>- 작업환경이 열악한 유해공정을 보유하고 있는 사업장을 대상으로 고소음발생작업, 근골격계
                                      부담작업 보유 또는 유해 화학물질을 취급하거나 분진작업에 대하여 쾌적한 작업환경조성을 위한 설비개선에 필요한 클린사업 <br/>컨설팅
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td height="20" width="700"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default Musculoskeletal
