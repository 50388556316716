import React from 'react'
import reperence1 from '../../assets/img/reperence1.jpg';
import reperence2 from '../../assets/img/reperence2.jpg';
import reperence3 from '../../assets/img/reperence3.jpg';
import {Link} from "react-router-dom";

const HomeReference = (props) => {
    const { category, references } = props;

    const displayCategory = () => {
        if (category === 'construction') return "건설업 자료실"
        else if (category === 'safety') return "안전진단 자료실"
        else return "제조업 자료실"
    }
    const displayLink = (cateogry) => {
        if (category === 'construction')
            return '/reference/'
        else if (category === 'safety')
            return <img src={reperence2} className="on" />
        else
            return <img src={reperence3} className="on" />
    }
    const displayImg = () => {
        if (category === 'construction')
            return <img src={reperence1} className="on" />
        else if (category === 'safety')
            return <img src={reperence2} className="on" />
        else
            return <img src={reperence3} className="on" />
    }
    /*
    return (
        <>
            <div className="reference_con on">
                <h4 className="tit">{displayCategory()}</h4>
                <Link to={"/references/" + category} className="more" title="더보기">더보기</Link>
                <ul className="board_list">
                    {references[category].length > 0 ? references[category].map((reference, idx) => {
                        return <li key={idx}><Link to={"/reference/" + reference.id}>{reference.title}</Link></li>
                    }) : <li><a>등록된 자료가 없습니다.</a></li>}
                </ul>
            </div>
            <div className="imgbox">
                {displayImg()}
            </div>
        </>
    )
    */
    return (
        <>
            <div className="reference_con on">
                <h2 className="tit" >최근 게시물</h2>
                <Link to={"/references"} className="more" title="더보기">더보기</Link>
                <ul className="board_list">
                    {references.length > 0 ? references.map((reference, idx) => {
                        return <li key={idx}><Link to={"/reference/" + reference.id}>{reference.title}</Link></li>
                    }) : <li><a>등록된 자료가 없습니다.</a></li>}
                </ul>
            </div>
            <div className="imgbox">
                {displayImg()}
            </div>
        </>
    )
}

export default HomeReference;