import React from 'react'

import part_1_con from '../../../assets/img/business/part_1_con.gif'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const SafetyManagement = () => {

  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>안전관리자 위탁</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">안전관리자 위탁</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
        <h4>개요</h4>
        <hr className="border-gray-700"/>
        <h5>상시 근로자수 50명 이상을 사용하는 사업장의 경우 안전관리자를 선임하여 안전에 관한 기술적인 사항에 대해 사업주 또는 관리책임자를 보좌하고 관리감독자에게 조언·지도하도록 하고 있으며, 이 경우 안전관리자의 업무를 안전관리전문기관에 위탁(건설업 제외) 가능함.</h5>
        <span>[산업안전보건법 제17조]</span>
        <hr className="border-gray-700"/>
        <h4>안전관리업무 위탁대상</h4>
        <h5>상시근로자 50명 이상 사업장(건설업 제외)으로 안전관리자 법적 선임의무가 있는 사업장 및 안전관리업무를 위탁하고자 하는 사업장(산업안전보건법 시행령 별표3)</h5>
        <hr className="border-gray-700"/>
        <h5>1. 월 2회 이상 사업장 안전관리상태 점검 및 법령 위반사항, 불안전한 요소에 대한 구체적인 개선대책 제시</h5>
        <h5>2. 신규계약, 중대재해발생 및 동종업종평균재해율 이상인 사업장에 대한 정밀안전점검 실시</h5>
        <h5>3. 산업재해발생의 원인조사 및 재발방지를 위한 기술적 조언·지도</h5>
        <h5>4. 최신 계측장비를 활용한 점검 및 개선대책 제시</h5>
        <h5>5. 안전기술자료 및 교육자료 등 각종 안전관련 자료제공</h5>
        <h5>6. 산업안전보건법, 산업재해보상보험법 및 근로기준법 등 관련 법률 상담</h5>
            <h5>7. 기타 안전보건개선계획서 작성 등 각종 안전관련 업무지원</h5>
        <hr className="border-gray-700"/>
        <h4>위반시 법적 제재 사항</h4>
        <hr className="border-gray-700"/>
        <h5>산업안전보건법 시행령 별표 35(과태료의 부과기준)</h5>
        <table className="business_table2" style={{width : "100%"}}>
        <tr>
            <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>위반행위</th>
            <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}} >근거 법조문</th>
            <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>과태료 금액(만원)</th>

        </tr>

            <tbody style={{textAlign : "center"}}>

            <tr>
                <td>안전관리자 또는 보건관리자를 두지 않은 경우	</td>
                <td>법 제175조 제5항 제1호	</td>
                <td>1차 : 500만원 <br/>
                2차 : 500만원 <br/>
                3차 : 500만원 <br/></td>
            </tr>
            <tr>
                <td>안전관리자 또는 보건관리자를 두고 업무를 수행하도록 하지 않은 경우	</td>
                <td>법 제175조 제5항 제1호	</td>
                <td>1차 : 300만원 <br/>
                2차 : 400만원 <br/>
                3차 : 500만원 <br/></td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default SafetyManagement
