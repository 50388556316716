import React from 'react';

export default function NotFoundPage() {
    return (
        <div style={{display : "flex", alignItems: "center", width : "100%", height : "80vh", textAlign : "center"}}>
            <div style={{margin : "0 auto"}}>
            <h2 style={{fontWeight : "bold"}}>요청하신 페이지를 찾을 수 없습니다.</h2>
            <p>링크를 잘못 입력하셨거나 페이지가 삭제. 이동되었을 수 있습니다.</p>
            </div>
            
        </div>
        
    )
}