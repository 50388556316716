import React from 'react'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";

const Adjacent = () => {
    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>인접지 사전조사</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
        <div className="container px-3">
      <h1 className="text-3xl font-bold">인접지 사전조사</h1>
      <hr className="border-gray-700"/>

        <div>
            <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>내용</th>
                            </tr>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>목적</td>
                                    <td>현장주변 시설물에 대한 공사 전 건축물의 현황조사와 점검을 통해 공사 중 발생될 수 있는 손상을 미연에 방지함과 동시에 건물손상 초기에
                                    이에 대한 대책을 마련하여 분쟁의 소지를 사전에 방지하고, 안전점검 및 계측을 통해 현장주변 주민의 불안감을 해소하기위함이다.</td>
                                </tr>
                            <tr>
                                <td>조사시기</td>
                                <td>기존 건축물 철거공사 중</td>
                            </tr>
                            <tr>
                                <td>조사방법</td>
                                <td>신축공사현장에 인접한 주변 건축물의 공용부 및 외부, 담장 등에서 정밀한 육안조사 (균열 및 누수, 박리, 박락 등)와 계측을 통한 병위조사(균열의 진행 및 건축물의 기울기 상태 초기값 등)를 실시한다.</td>
                            </tr>

                            <tr>
                                <td>조사사항</td>
                                <td>1. 신축공사 관련자료 검토 분석<br/>
                                    2. 현장 주변 인접건물의 상태조사 (균열, 박리, 박락, 누수 등)<br/>
                                    3. 조사부위 사진 촬영<br/>
                                    4. 균열게이지 설치<br/>
                                    5. 경사계 설치<br/>
                                    6. 비디오 촬영
                                </td>
                            </tr>

                            <tr>
                                <td>공사 중 발생할 수 있는 요인</td>
                                <td>1. 건물철거 진동, 충격, 소음, 분진의 발생<br/>
                                    2. 엄지말뚝, 파일 등 매입/향타 시 진동, 충격, 소음<br/>
                                    3. 지하굴착, 암반파쇄, 발파 시 진동, 충격, 소음<br/>
                                    4. 흙막이 변위, 지하수 유출, 수위저하에 의한 지반침하 및 인접건물 변위발생<br/>
                                    5. 구조체 공사 시 소음, 진동, 분진발생
                                </td>
                            </tr>

                            </tbody>
                        </table>
        </div>
        </div>
            </div>
        </div>
        </div>
    )
}
export default Adjacent
