import React from 'react'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const Regular = () => {
//제3종 시설물 정기점검
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>제3종 시설물 정기점검</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
           <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">제3종 시설물 정기점검</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
          <div className="business_div">
          <h4>목적</h4>
            <hr className="border-gray-700"/>
          <h5>소관 시설물의 안전과 기능을 유지하기 위하여 정기적으로 안전점검을 실시해야 한다.</h5>
            <hr className="border-gray-700"/>
        </div>
        <div className="">
          <h4>대상</h4>
          <hr className="border-gray-700"/>
          <h5>1. 준공 후 15년이 경과된 11층 이상 16층 미만 또는 연면적 5천제곱미터 이상 3만제곱미터 미만인 건축물(동물 및 식물 관련 시설 및 자원순환 관련 시설은 제외) </h5>
          <h5>2. 준공 후 15년이 경과된 연면적 1천제곱미터 이상 5천제곱미터 미만인 문화 및 집회시설, 종교시설, 판매시설, 운수시설, 의료시설, 교육연구시설(연구소는 제외), 노유자시설, 수련시설, 운동시설, 숙박시설, 위락시설, 관광 휴게시설, 장례시설)</h5>
          <h5>3. 연면적 500제곱미터 이상 1천제곱미터 미만인 문화 및 집회시설(공연장 및 집회장만 해당), 종교시설 및 운동시설</h5>
          <h5>4. 준공 후 15년이 경과된 연면적 300제곱미터 이상 1천제곱미터 미만인 위락시설 및 관광휴게시설</h5>
          <h5>5. 준공 후 15년이 경과된 연면적 1천제곱미터 이상인 공공업무시설(외국공관은 제외)</h5>
          <h5>6. 준공 후 15년이 경과된 연면적 5천제곱미터 미만인 지하도상가(지하보도면적을 포함)</h5>

        </div>
        <hr className="border-gray-700"/>
        <div className="">
          <h4>안전점검</h4>
          <hr className="border-gray-700"/>
          <h5>정기안전점검</h5>
          <h5>시설물의 상태를 판단하고 시설물이 점검 당시의 사용요건을 만족시키고 있는지 확인할 수 있는 수준의 외관조사를 실시하는 안전점검(「시설물의 안전 및 유지관리에 관한 특별법 시행규칙」 제2조제1호)</h5>
          <hr className="border-gray-700"/>
          <h5>안전등급</h5>
          <h5>안전점검 등을 실시하는 자는 안전점검 등의 실시결과에 따라 다음 표에 해당하는 안전등급을 지정해야 합니다(「시설물의 안전 및 유지관리에 관한 특별법」 제16조제1항 및 「시설물의 안전 및 유지관리에 관한 특별법 시행령」 별표 8).</h5>
          <table className="business_table2">
            <thead>
              <tr>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>안전등급</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>시설물의 상태</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A (우수)</td>
                <td>문제점이 없는 최상의 상태</td>
              </tr>
              <tr>
                <td>B (양호)</td>
                <td>보조부재에 경미한 결함이 발생하였으나 기능 발휘에는 지장이 없으며, 내구성 증진을 위하여 일부의 보수가 필요한 상태</td>
              </tr>
              <tr>
                <td>C (보통)</td>
                <td>주요부재에 경미한 결함 또는 보조부재에 광범위한 결함이 발생하였으나 전체적인 시설물의 안전에는 지장이 없으며,<br/> 주요부재에 내구성, 기능성 저하 방지를 위한 보수가 필요하거나 보조부재에 간단한 보강이 필요한 상태</td>
              </tr>
              <tr>
                <td>D (미흡)</td>
                <td>주요부재에 결함이 발생하여 긴급한 보수·보강이 필요하며 사용제한 여부를 결정하여야 하는 상태</td>
              </tr>
              <tr>
                <td>E (불량)</td>
                <td>주요부재에 발생한 심각한 결함으로 인하여 시설물의 안전에 위험이 있어 즉각 사용을 금지하고 보강 또는 개축을 하여야 하는 상태</td>
              </tr>


            </tbody>
          </table>
          <span>안전점검의 실시시기(「시설물의 안전 및 유지관리에 관한 특별법 시행령」 별표 3)<br/></span>
          <table className="business_table2" style={{marginTop : "30px"}}>
            <thead>
              <tr>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>A 등급</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>B-C 등급</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>D-E 등급</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td>정기안전점검</td>
              <td>반기에 1회 이상</td>
              <td>반기에 1회 이상</td>
              <td>1년에 3회 이상</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default Regular
