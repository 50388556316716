import React from 'react'
import img from '../../../assets/img/business/part_1_con (1).gif'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";

const TechnicalGuidance = () => {
  //재해예방 기술지도
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>재해예방 기술지도</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
          <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">재해예방 기술지도</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
        <h4>개요</h4>
        <hr className="border-gray-700"/>
        <h5>수급인 또는 자체사업을 하는 자 중 공사금액 1억원 이상 120억원(「건설산업기본법 시행령」 별표 1의 토목공사업에 속하는 공사는 150억원) 미만인 공사를 하는 자와 「건축법」 제11조에 따른 건축허가의 대상이 되는 공사를 하는 자는, 산업재해 예방을 위한 [재해예방 전문지도기관]의 지도를 받도록 하고 있음.</h5>
        <span>[산업안전보건법 제73조, 시행규칙 제59조]</span>
        <hr className="border-gray-700"/>
        <h4>대상 및 내용</h4>
        <hr className="border-gray-700"/>
        <table className="business_table2" style={{width : "100%"}}>
                            <thead>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>대상</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>내용</th>
                            </tr>
                            </thead>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>공사금액 1억이상 120억원(토목 : 150억) 미만의 건설공사</td>
                                    <td>1. 현장여건에 적합한 안전활동 기법지도<br/>
                                      2. 안전·보건교육 자료 등 제공<br/>
                                      3. 산업안전보건관리비 사용계획 등 지도<br/>
                                      4. 안전 관련 양식 등 제공<br/>
                                      5. 기타 표준 안전작업지침에 관한 사항 지도, 점검</td>
                                </tr>
                            </tbody>
        </table>
        <hr className="border-gray-700"/>
        <h4>기술지도 제외 공사</h4>
        <hr className="border-gray-700"/>
        <h5>1. 공사기간 1개월 미만인 공사<br/>
        2. 육지와 연결되지 아니한 도서지역(제주도 제외)에서 이루어지는 공사<br/>
        3. 안전관리자를 선임하여 안전관리자 업무만을 전담하도록 하는 공사<br/>
        4. 유해·위험방지계획서 제출대상공사<br/>
        </h5>
        <hr className="border-gray-700"/>
        <h4>기술지도 계약 및 횟수</h4>
        <hr className="border-gray-700"/>
        <h5>1. 공사 착공전 기술지도 계약을 체결하고, 발주자에게 기술지도계약서 제출<br/>
            2. 기술지도 횟수 : 공사기간 중 월 2회<br/>
        </h5>
        <hr className="border-gray-700"/>
        <h4>위반시 법적 제제 사항</h4>
        <hr className="border-gray-700"/>
        <h5>산업안전보건법 시행령 별표 35(과태료의 부과기준)</h5>
        <table className="business_table2" style={{width : "100%"}}>
            <thead>
        <tr>
            <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>위반행위</th>
            <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}} >근거 법조문</th>
            <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>과태료 금액(만원)</th>

        </tr>
</thead>
            <tbody style={{textAlign : "center"}}>

            <tr>
                <td>법 제73조 제1항을 위반하여 재해예방 전문지도기관의 지도를 받지 않은 경우</td>
                <td>법 제175조 제6항 제6호</td>
                <td>1차 : 200만원 <br/>
                2차 : 250만원 <br/>
                3차 : 300만원 <br/></td>
            </tr>
        </tbody>
        </table>

      </div>
    </div>
            </div>
      </div>
          </div>
  )
}
export default TechnicalGuidance
