import React from 'react';
import bg_greeing from '../../../assets/img/bg_greeing.jpg';
import {Link} from "react-router-dom";
const Intro = () => {

    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">인사말</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/intro">회사소개</Link></li>
            <li><Link to="/intro">인사말</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox greeting">
          <h2>"안전재해예방"을 위한 관리,<br />한국재해예방관리원과 함께 합니다.</h2>
          <p className="textcenter subtext">
            한국재해예방관리원(주)은 안전진단기관, 재해예방전문기관, 안전관리대행기관으로서 사회적인 역할과 책임을 다하고자 재해예방관리 비전 제시와 더불어 다음과 같은 사명을 깊이 인식하고 이를 바탕으로 중소 건설현장 및 제조현장을 중심으로 쾌적한 작업환경과 안전한 작업장을 조성하여 무재해 산업사회를 이루는데 목적을 두고 산업안전보건 선진화에 기여하고자 합니다.
          </p>
          <div className="detail">
            <div className="imgbox">
              <img src={bg_greeing} alt="" />
            </div>
            <div className="textbox">
              <h4>첫째,</h4>
              <p>
                우리는 21세기 새로운 사회가 요구하는 새로운 개념의 산업재해예방기법을 정립하여 제시하고 무재해를 위한 길잡이가 되겠습니다.
              </p>
              <h4>둘째,</h4>
              <p>
                우리는 안전문화 정착을 위해 기업(건설 및 제조)이 요구하는 모든 기능과 역할을 신뢰감 조성과 고객중심의 지향적인 서비스를 제공하겠습니다.
              </p>
              <h4>셋째,</h4>
              <p>
                과거의 낡은 안전관리 시스템을 과감히 떨치고 국제 인증시대에 대비, 안전보건관리의 수준을 올려 대외 경쟁력을 제고시키고 재해예방을 위한 기업의 자율 안전관리 활동에 폭넓고 능동적으로 참여 할 수 있도록 현장의 특성에 맞는 재해예방 시스템 개발에 앞장서겠습니다.<br /><br />
                저희 한국재해예방관리원(주)은 기존의 단순한 안전진단기관, 재해예방전문기관, 안전관리대행기관 등 서비스 제공에만 머무르지 않고 보다 광범위한 영역에서의 산업재해예방에 대한 관리, 교육, 기술을 터득하고 심도있는 기술지도를 통하여 귀사의 대외 경쟁력을 제고함은 물론 올바른 안전문화 정착에 열과 성을 다할 것을 다짐합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
}
export default Intro