import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/common.css"

import AdminLayout from "layouts/Admin.js";
import Main from "./layouts/Main";
import Home from "./views/home/Home";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ScrollToTop from "./views/ScrollToTop";

ReactDOM.render(
  <BrowserRouter>
      <ScrollToTop/>
      <Switch>
        <Route  path="/admin"  render={(props) => <AdminLayout {...props} />} />
        <Route path="/" component={Main}/>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
