import React, {useState, useEffect} from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import auth from "../api/AuthAPI"
import axios from "axios";

function User({match}) {
  const [inputs, setInputs] = useState({
    "email" : "",
    "username" : "",
    "usernumber" : "",
    "userphone" : ""
  })
  const [access, setAccess] = useState(0)
  const server = "http://18.179.138.213:3001";

  const { email, userphone, username, usernumber} = inputs
  const handleChange = (e) => {

    let {value, name} = e.target

    setInputs({
      ...inputs,
      [name] : value
    })

    if (name === "userphone" && value.length === 10) {
      setInputs({
      ...inputs,
      userphone : value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    })
    }
    if (name === "userphone" && value.length === 13) {
      setInputs({
        ...inputs,
        userphone : value.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
      })
    }

  }
  const handleAccessChange = (e) => {
    setAccess(e.target.value)
  };

  async function find_user(){
    try {


      let token = sessionStorage.getItem("token");
      let user_id = sessionStorage.getItem('id');
      let target_id = match.params.id

      let data = JSON.stringify({"token": token, "user_id": user_id, "target_id": target_id})
      await axios.post(server + "/finduser", data)
          .then(response => {
            if (response.data.response === 200) {
              setInputs({
                "email": response.data.email,
                "username": response.data.username,
                "usernumber": response.data.usernumber,
                "userphone": response.data.userphone,

              });
              setAccess(response.data.access);
            } else {
              alert("데이터를 불러오는데 실패하였습니다.");
            }
          })
    }catch (e) {
      alert("데이터를 불러오는데 실패하였습니다.");
    }
  }
  useEffect(() => {
    find_user();
  }, [])

  const handleSave = (e) => {
    e.preventDefault()
    let target_id = match.params.id
    if (userphone === "" || username === "", usernumber ===  "") return alert("빈칸을 입력하시오.")
    auth.update_user(target_id, userphone, username, usernumber, access).then(response => {
      let datas = response.data
      if (datas['response'] == 200) {
        alert('저장완료\n해당 유저는 재로그인이 필요합니다.')
      }else {
        alert('저장실패')
      }
    }).catch(err => {
      alert("네트워크 에러 \n" + err )
    })
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">직원정보수정</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label>이름</label>
                        <Form.Control
                          placeholder="Company"
                          type="text"
                          name="username"
                          value={username}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label>전화번호</label>
                        <Form.Control
                          type="text"
                          name="userphone"
                          value={userphone}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label>직원번호</label>
                        <Form.Control
                          type="text"
                          name="usernumber"
                          value={usernumber}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="3">
                      <Form.Group>
                        <label>권한</label>
                        <Form.Control as="select" onChange={handleAccessChange} value={access}>
                          <option name="manage" value="2">관리자</option>
                          <option name="normal" value="1">일반</option>
                          <option name="wait" value="0">대기중</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    onClick={handleSave}
                  >
                    저장하기
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
}

export default User;
