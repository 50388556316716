import React, {useState} from 'react';
import {Link} from "react-router-dom";

const Manpower = () => {
  const [category, setCategory] = useState("construction");

  const handleClick = (e) => {
    setCategory(e.target.getAttribute('name'));
  }
  return (
    <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">인력현황</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/intro">회사소개</Link></li>
            <li><Link to="/intro/equipment">인력현황</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox greeting">
            <h3 style={{fontWeight : "bold"}}>인력현황</h3>
            <hr/>
            <div className='org-category-container'>
              <li name="construction" className={category === "construction" ? "org-category-active org-category" : "org-category"} onClick={handleClick}>건설사업부</li>
              <li name="manufacturing" className={category === "manufacturing" ? "org-category-active org-category" : "org-category"} onClick={handleClick}>제조사업부</li>
            </div>
            <div className="org">
              { category === "construction" ?
              <table >
              <thead>
              <tr>
                <th>직위</th>
                <th>성명</th>
                <th>업무분장내용</th>
                <th>비고</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>대표이사</td>
                  <td>박용구</td>
                  <td class="manpower-content">
                    1. 회사경영 총괄<br/>
                    2. 경영검토 주관<br/>
                    3. 품질목표 수립<br/>
                    4. 품질절차서 문서의 승인<br/>
                    5. 사업계획 수립 검토승인
                  </td>
                  <td>품질관리 총괄</td>
                </tr>
                <tr>
                  <td>전무이사<br/>(건설안전기술사)</td>
                  <td>이기태</td>
                  <td class="manpower-content">
                  1. 재해예방기술지도업무/사망사고현장 모니터링<br/>
                  2. 40억이상 기술사 점검<br/>
                  3. 컨설팅 업무총괄(자율컨설팅 발생시)<br/>
                  4. 자료개발, 연구<br/>
                  5. 교육지원 업무 및 내부자체교육 강사<br/>
                  6. 경기 남부, 동부 현장 담당<br/>
                  7. 내부 자체교육 강사
                  </td>
                  <td>중대재해현장<br/>대책회의 주관</td>
                </tr>
                <tr>
                  <td>상무이사</td>
                  <td>김현주<br/>(품대인)</td>
                  <td class="manpower-content">
                  1. 품질시스템의 수립, 실행, 유지<br/>
                  2. 각종 양식관리<br/>
                  3. 기술지도업무 총괄<br/>
                  4. 내부자체교육 강사<br/>
                  5. 산업재해의 기록,통계. 분석<br/>
                  6. 인천,경기북부,경기서부 담당<br/>
                  7. MOU 관련 협업 진행<br/>
                  8. 캠페인 추진관련 현장발굴, 진행
                  </td>
                  <td>
                    교육훈련 담당<br/>
                    문서자료관리(정)<br/>
                    지도업무 총괄<br/>
                    재해율통계 담당
                  </td>
                </tr>
                <tr>
                  <td>이사</td>
                  <td>이병택</td>
                  <td class="manpower-content">
                    1. 재해예방기술지도 업무<br/>
                    2. 평택지역 대관 업무(고용노동부, 안전공단)<br/>
                    3. 경기 서부, 경기 남부 현장 담당
                  </td>
                  <td>평택지역 대관업무</td>
                </tr>
                <tr>
                  <td>실장</td>
                  <td>최기화</td>
                  <td class="manpower-content">
                  1. 재해예방기술지도 업무<br/>
                  2. 화성지원 대관업무<br/>
                  3. 경기 남부, 서부, 동부, 인천 현장 담당
                  </td>
                  <td>일반사업장 전담<br/>교육지원</td>
                </tr>
                <tr>
                  <td>이사</td>
                  <td>오남국</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 중부관할 대관업무 , 회의 참석<br/>
                  3. 캠페인 지원<br/>
                  4. 경기서부, 인천 현장 담당
                  </td>
                  <td>일반사업장 및 국고지원 겸임</td>
                </tr>
                <tr>
                  <td>부장</td>
                  <td>이기철</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 경기 북부지역 대관업무<br/>
                  3. 경기 북부,서부,인천 현장 담당
                  </td>
                  <td>일반 사업자 전담</td>
                </tr>
                <tr>
                  <td>부장</td>
                  <td>이정세</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 중부청 대관업무<br/>
                  3. 인천, 경기 북부,서부, 현장 담당
                  </td>
                  <td>일반 사업자 전담</td>
                </tr>
                <tr>
                  <td>이사</td>
                  <td>송정환</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 인천, 경기북부, 서부, 남부 현장 담당
                  </td>
                  <td>일반 전담</td>
                </tr>
                <tr>
                  <td>이사</td>
                  <td>서기석</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 측정장비 내부 교육 담당<br/>
                  3. 장비 검교정 담당<br/>
                  4. 경기 서부, 남부, 동부 현장 담당
                  </td>
                  <td>일반 전담<br/>(측정장비 담당)</td>
                </tr>
                <tr>
                  <td>부장</td>
                  <td>채삼석</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 경기 서부, 경기 북부, 경기 동부 현장 담당
                  </td>
                  <td>일반 전담</td>
                </tr>
                <tr>
                  <td>이사</td>
                  <td>최성완</td>
                  <td class="manpower-content">
                  1. 기술지도 업무<br/>
                  2. 경기남부,동부,서부 현장 담당<br/>
                  3. OPL 제작, 현수막제작, 교육자료 제작 보조<br/>
                  4. 캠페인 지원 보조<br/>
                  5. 위험성평가 관련 자료,정보 수집, 제작보조
                  </td>
                  <td>일반 전담<br/>제공품 관리(부)</td>
                </tr>
                <tr>
                  <td>실장<br/>과장</td>
                  <td>이지숙<br/>김혜리</td>
                  <td class="manpower-content">
                  1. 측정장비 유지,보존<br/>
                  2. 경리,회계 업무<br/>
                  3. 문서자료관리(문서접,발송대장) <br/>
                  4. 사업장 현황관리(계약,수금관계) <br/>
                  5. 전산관리
                  </td>
                  <td>문서자료관리(부)<br/>(지도요원 외 업무지원 인력)</td>
                </tr>
              </tbody>
          </table>
            :
              <table>
                  <thead>
                  <tr>
                    <th>연번</th>
                    <th>성명</th>
                    <th>직급</th>
                    <th>부서</th>
                    <th>업무내역</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>박OO</td>
                      <td>대표이사</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                        - 사업총괄
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>여OO</td>
                      <td>전문위원</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 내부결재 검토<br/>
                      - 안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>조OO</td>
                      <td>상무</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 직원운영 계획 관리<br/>
                      - 장비사용 기본 교육 담당<br/>
                      - 장비 활용 현황 집계 관리 -안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>김OO</td>
                      <td>상무</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 사업계획서 작성<br/>
                      - 기술지도 업무 수행 지침 등(매뉴얼)제ᆞ개정<br/>
                      - 사업계획 수립 및 사업 평가 및 분석<br/>
                      - 내부결재<br/>
                      - K2B 실적 관리<br/>
                      - 안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>박OO</td>
                      <td>이사</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 정밀점검 계획수립 및 이행상태 관리(집계)<br/>
                      - 고용노동부, 안전보건공단 회의 참석 등<br/>
                      - 고객만족도 관리 분석<br/>
                      - 캠페인 운영관리<br/>
                      – 현수막 게시 및 실적관리<br/>
                      - 안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>김OO</td>
                      <td>이사</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 산업재해분석(통계유지)<br/>
                      - 위험성평가 인정 사업장 실적관리<br/>
                      - 안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>이OO</td>
                      <td>이사</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 장비 업무총괄<br/>
                      - 장비 관리대장 업데이트 등 기록관리<br/>
                      - 장비담당(대장관리, 검ᆞ교정)<br/>
                      - 장비 자체점검 실시(작동상태, 건전지 교환)<br/>
                      - 장비 구매 검토 등<br/>
                      - 안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>백OO</td>
                      <td>팀장</td>
                      <td>제조 사업부</td>
                      <td class="manpower-content">
                      - 법규검토및등록<br/>
                      - 사내외교육계획운영<br/>
                      - 매월 기술자료 제작, 배포<br/>
                      - 안전포스터 제작, 배포<br/>
                      - 안전자료 제작 배포(홍보물 포함)<br/>
                      - 안전관리 위탁업무
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>이OO</td>
                      <td>실장</td>
                      <td>관리부</td>
                      <td class="manpower-content">
                      - 문서등록 및 기록물 등록관리 대장 관리<br/>
                      - 위탁사업장 등록 및 계약서 관리<br/>
                      - 급여, 4대보험 등 관리 부분 관리<br/>
                      - 홈페이지 관리<br/>
                      - 대외 공문접수 및 발송
                      </td>
                    </tr>
                  </tbody>
              </table>
            }

            </div>
        </div>
    </div>
  );
};

export default Manpower;
