import React, {useState, useEffect} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import ContractMakeForm from "../../../components/Contract/ContractMakeForm";
import {check_params, parse_krw} from "../../../utils";
import {useHistory} from "react-router-dom";
import ContractForm from "../../../components/Contract/ContractForm";
import SearchContract from "../../../components/Contract/SearchContract";
import axios from "axios";
import CompleteContractForm from "../../../components/Contract/CompleteContractForm";
import CompleteMakeForm from "../../../components/Contract/CompleteMakeForm";
import ComplelteContractForm from "../../../components/Contract/CompleteContractForm";


const CompleteContractMake = () => {
    const server = "http://18.179.138.213:3001"

    const check_number_value = ["technical_price", "construction_price", "accrued_price"]
    const [disable, setDisable] = useState(false);
    const history = useHistory();
    const [inputs, setInputs] = useState({
        company : '',
        agent : '',
        orderer : '',
        office_address : '',
        construction_name : '',
        industrial_number : '',
        business_number : '',
        location : '',
        phone : '',
        orderer_address : '',
        term_start : '',
        term_end : '',
        construction_start : '',
        construction_end : '',
        technical_class : 'Construction',
        technical_price : "",
        technical_number : 0,
        construction_price: "",
        accrued_price: "",
        contract_date: "",
        remark : "",
        construction_phone : "",
        username : ""
    });

    const {company, agent, orderer, office_address, construction_name, industrial_number, business_number, location, phone, orderer_address, term_start, term_end,
    construction_start, construction_end, construction_phone, technical_class, technical_price, technical_number, construction_price, accrued_price, contract_date, print_number, remark, contract_url} = inputs

    const [completedate, setCompletedate] = useState("");
    const [teachdates, setTeachdates] = useState([]);
    const [teach, setTeach] = useState("");
    const [completeremark, setCompleteremark] = useState("");

    const [contracts, setContracts] = useState([]);
    const [newContracts, setNewContracts] = useState([]);
    const [searchModel, setSearchModel] = useState(false);
    const [search, setSearch] = useState("");
    const [contractId, setContractId] = useState(0)
    const [isNew, setIsNew] = useState(0)
    async function get_contracts(){
        const token = sessionStorage.getItem('token')
        const user_id = sessionStorage.getItem('id')
        let post_data = {token : token, user_id : user_id}

        await axios.post(server + "/usercontract", post_data).then((response) => {
            let datas = response.data
            if (datas['response'] == 200){
                let rs = datas['datas'].filter( data => {
                    return !data.complete
                })
                setContracts(rs);
                setNewContracts(rs);
            }else {
                alert("계약서를 가져오는데 실패하였습니다.")
            }
        }).catch((err) => {
            alert("계약서를 가져오는데 실패하였습니다.")
        })
    }

    function handleSearch(e){
        setSearch(e.target.value);
    }
    const handleCheck = (complete) => {
        return Object.keys(complete).length === 0 && complete.constructor === Object
      }
    function handleSearchClick(e){
        const { index } = e.target.dataset;
        for (let contract of contracts) {

            if (contract['id'] == index) {
                setInputs(contract)
                handleSearchModelClose();
                setDisable(true);
                setContractId(contract['id']);
                if (contract['complete'] == null || handleCheck(contract['complete'])) {

                }else{
                    setIsNew(1);
                }
                break;
            }
        }
    }
    function handleSearchModelOpen(){
        setSearchModel(true);
        get_contracts();
    }
    function handleSearchModelClose(){
        setSearchModel(false);
    }
    function check_complete(){
        // 발행 날짜만 체크후 넘김
        if(completedate == "") return true;
        else return false;
    }
    function check_contract(){
        if (construction_name === "" || agent === "" || company === "" || location === "" || construction_price === 0 || orderer === "" ||
        construction_start === "" || construction_end === "" || term_end === "" || term_start === "" || technical_class === "" || technical_number === 0 || technical_price === 0) {
            return true;
        }
        return false;

    }
    async function handleSave(){
        let url = ""
        let post_data = {}
        let date_objects = ""
        let user_id = ""
        let token = ""
        try{
             token = sessionStorage.getItem('token')
             user_id = sessionStorage.getItem('id')



            teachdates.map((date, idx) => {
              date_objects += date + ","
            })
        }catch (e) {
            alert("새로고침 후 재시도 하세요")
            ;
        }
        let complete_input = {
            lead_date : date_objects,
            date : completedate,
            remark : completeremark,
        }
        // 기술지도계약서를 처음 만듬
        if (contractId === 0) {
            if (check_contract()) {
                alert("기술지도계약서 내용을 입력하세요");

                return;
            }
            if (check_complete()) {
                    alert("완료증명서 내용을 입력하세요");
                    return;
                }
            let userphone = sessionStorage.getItem('userphone')
            let username = sessionStorage.getItem('username')
            let usernumber = sessionStorage.getItem('usernumber')
            username = username + " (" + userphone + ")"
            let _inputs = inputs;
            _inputs.username = username;
            post_data = {
                token : token,
                user_id : user_id,
                contract : _inputs,
                complete : complete_input,
                usernumber : usernumber
            }
            url = "/completemakenew"

        }
        else {
            if (check_complete()) {
                alert("완료증명서 내용을 입력하세요");
                return;
            }
            post_data = {
                token : token,
                user_id : user_id,
                contract_id : contractId,
                complete : complete_input,
            }
            url = "/completemake"
        }
        try {

            await axios.post(server + url, post_data)
                .then(response => {
                    if(response.data.response == 200) {
                            alert("저장이 완료되었습니다.")
                        history.push("/admin/contract/" + response.data.id)
                    }else {
                        alert("저장이 실패하였습니다.")
                    }

                }).catch(e => {
                    alert("네트워크 오류 ");
                })

        } catch (e) {
        }

    }
    function handleInit(){
        setInputs({
        company : '',
        agent : '',
        orderer : '',
        office_address : '',
        construction_name : '',
        industrial_number : '',
        business_number : '',
        location : '',
        phone : '',
        orderer_address : '',
        term_start : '',
        term_end : '',
        construction_start : '',
        construction_end : '',
        technical_class : 'Construction',
        technical_price : "",
        technical_number : 0,
        construction_price: "",
        accrued_price: "",
        contract_date: "",
        remark : "",
        construction_phone : "",
        username : ""
        });
        setDisable(false);
        setCompletedate("");
        setTeach("");
        setTeachdates([]);
        setCompleteremark("");
        setIsNew(0);
        setContractId(0);
    }

    const handleChange = (e) => {
        let {value, name} = e.target
        check_number_value.map(check_name => {
          if (check_name == name) {
            value = parse_krw(value)
          }
        })

        setInputs({
          ...inputs,
          [name] : value
        })
    }
    const handleSetData = (e) => {
        e.preventDefault()
        setInputs({
          ...inputs,
          term_start: construction_start,
          term_end: construction_end
        })
    }
    const handleAddList = () => {
        if (teach === "") return alert("날짜를 입력하시오.")
        if (teachdates.length > 23 ) return alert("최대 지도횟수를 초과했습니다.")
        let newdate = teachdates.concat(teach)
        setTeachdates(newdate)
    }
    const handleRemove = (index) => {
        let newList = teachdates.filter((date, idx) => idx !== index)
        setTeachdates(newList)
    }

    useEffect(() => {
        let _contract = [...contracts]
        _contract = _contract.filter(value => {
            if (value['construction_name'].indexOf(search) === -1 && value['company'].search(search) === -1) {
                return false
            }
            return true
        })
        setNewContracts(_contract)

    }, [search])

    return (
        <Container fluid>
            <Row>
              <Col md="12">
                  <Card>
                      <div style={{display : "flex"}}>
                          <Card.Title as="h4" style={{padding : "15px", display : "flex"}}>
                              완료증명서 생성

                          </Card.Title>
                          <div style={{display : "flex", padding : "15px"}}>
                              <Button size="sm" style={{marginRight : "10px"}} onClick={handleSave}>저장하기</Button>
                              <Button size="sm" variant="danger" style={{marginRight : "10px"}} onClick={handleInit}>초기화</Button>
                              <Button size="sm" variant="success" onClick={handleSearchModelOpen}>기술지도계약서 검색하기</Button>
                          </div>
                      </div>
                      <hr style={{margin : "0px"}}/>
                      <SearchContract
                          search={search}
                          contracts={newContracts}
                          searchModel={searchModel}
                          handleSearch={handleSearch}
                          handleSearchClick={handleSearchClick}
                          handleSearchModelOpen={handleSearchModelOpen}
                          handleSearchModelClose={handleSearchModelClose}
                      />
                      <CompleteMakeForm
                          inputs={inputs}
                          disable={disable}
                          handleAddList={handleAddList}
                          handleRemove={handleRemove}
                          handleCompleteDate={(e) => setCompletedate(e.target.value)}
                          handleTeach={(e) => setTeach(e.target.value)}
                          completedate={completedate}
                          teach={teach}
                          teachdates={teachdates}
                          handleChange={handleChange}
                          handleSetData={handleSetData}
                      />

                  </Card>
              </Col>
            </Row>
        </Container>
    )
}
export default CompleteContractMake;
