import React, {useState} from 'react';
import {Link} from "react-router-dom";
import gas from "./gas.png";
import gc from "./gc.png";
import lso from "./lso.png";
import oxygen from "./oxygen.png";
import lightmeter from "./lightmeter.png";
import dt9803 from "./dt9803.png";
import gic120c from "./gic120c.png";
import stethoscope from "./stethoscope.png";
import ms6208b from "./ms6208b.png";
import msn300 from "./msn300.png";
import mr501sid from "./mr501sid.png";
import nicety from "./nicety.png";
import sh from "./sh.png";
import oxygen2 from "./oxygen2.png";
import honeywell from "./honeywell.png";
import dhe from "./dhe.png";

const Equipment = () => {

  const [category, setCategory] = useState("construction");

  const handleClick = (e) => {
    setCategory(e.target.getAttribute('name'));
  }

  return (
    <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">장비현황</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/intro">회사소개</Link></li>
            <li><Link to="/intro/equipment">장비현황</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox greeting">
            <h3 style={{fontWeight : "bold"}}>장비현황</h3>
            <hr/>
            <div className='org-category-container'>
              <li name="construction" className={category === "construction" ? "org-category-active org-category" : "org-category"} onClick={handleClick}>건설사업부</li>
              <li name="manufacturing" className={category === "manufacturing" ? "org-category-active org-category" : "org-category"} onClick={handleClick}>제조사업부</li>
            </div>
            <div className="org">
            { category === "construction" ?
              <table>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>장비 고유번호</th>
                    <th>보유 수량</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={6}>1. 가스농도측정기<br/>(검사주기 : 2년)</td>
                    <td>E21010057</td>
                    <td rowSpan={6}>6대</td>
                    <td rowSpan={6}><img src={honeywell} alt="가스농도측정기"/></td>
                  </tr>
                  <tr>
                    <td>E21010017</td>
                  </tr>
                  <tr>
                    <td>E21010058</td>
                  </tr>
                  <tr>
                    <td>E17390374</td>
                  </tr>
                  <tr>
                    <td>E18210145</td>
                  </tr>
                  <tr>
                    <td>E17390373</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>2. 산소농도측정기<br/>(검사주기 : 2년)</td>
                    <td>02662235</td>
                    <td rowSpan={6}>6대</td>
                    <td rowSpan={6}><img src={oxygen2} alt="산소농도측정기"/></td>
                  </tr>
                  <tr>
                    <td>02297432</td>
                  </tr>
                  <tr>
                    <td>02297449</td>
                  </tr>
                  <tr>
                    <td>02297453</td>
                  </tr>
                  <tr>
                    <td>02297454</td>
                  </tr>
                  <tr>
                    <td>02297448</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>3. 접지저항측정기<br/>(검사주기 : 3년)</td>
                    <td>SH 000022</td>
                    <td rowSpan={6}>6대</td>
                    <td rowSpan={6}><img src={sh} alt="접지저항측정기"/></td>
                  </tr>
                  <tr>
                    <td>SH 000024</td>
                  </tr>
                  <tr>
                    <td>SH 000199</td>
                  </tr>
                  <tr>
                    <td>SH 007205</td>
                  </tr>
                  <tr>
                    <td>SH 007234</td>
                  </tr>
                  <tr>
                    <td>SH 00250</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>4. 절연저항측정기<br/>(검사주기 : 3년)</td>
                    <td>608007</td>
                    <td rowSpan={6}>6대</td>
                    <td rowSpan={6}><img src={dhe} alt="절연저항측정기"/></td>
                  </tr>
                  <tr>
                    <td>E 2485656</td>
                  </tr>
                  <tr>
                    <td>15209588</td>
                  </tr>
                  <tr>
                    <td>15207345</td>
                  </tr>
                  <tr>
                    <td>15207116</td>
                  </tr>
                  <tr>
                    <td>15209877</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>5. 조도계<br/>(검사주기 : 2년)</td>
                    <td>200901655</td>
                    <td rowSpan={6}>6대</td>
                    <td rowSpan={6}><img src={nicety} alt="조도계"/></td>
                  </tr>
                  <tr>
                    <td>200502072</td>
                  </tr>
                  <tr>
                    <td>200901504</td>
                  </tr>
                  <tr>
                    <td>200901506</td>
                  </tr>
                  <tr>
                    <td>200901510</td>
                  </tr>
                  <tr>
                    <td>200901652</td>
                  </tr>

                </tbody>
              </table>
            :
              <table >
                  <thead>
                  <tr>
                    <th>구분</th>
                    <th>장비 고유번호</th>
                    <th>보유 수량</th>
                    <th>비고</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan={9}>가스농도측정기<br/>(Honeywell EZ sence)</td>
                      <td>E 16250025</td>
                      <td rowSpan={9}>9대</td>
                      <td rowSpan={9}><img src={gas} alt="가스농도측정기"/></td>
                    </tr>
                    <tr><td>E 18170024</td></tr><tr><td>E 18210141</td></tr><tr><td>E 18210142</td></tr><tr><td>E 18210145</td></tr>
                    <tr><td>E 18210146</td></tr><tr><td>E 18390126</td></tr><tr><td>E 20150096</td></tr><tr><td>E 21010017</td></tr>
                  <tr>
                      <td rowSpan={9}>산소농도측정기<br/>(Smart Sensor)</td>
                      <td>AR 8100 (02297432)</td>
                      <td rowSpan={9}>9대</td>
                      <td rowSpan={9}><img src={oxygen} alt="산소농도측정기"/></td>
                    </tr>
                    <tr><td>AR 8100 (02297448)</td></tr><tr><td>AR 8100 (02297449)</td></tr><tr><td>AR 8100 (02297453)</td></tr><tr><td>AR 8100 (02297454)</td></tr>
                    <tr><td>AR 8100 (02397455)</td></tr><tr><td>AR 8100 (01920446)</td></tr><tr><td>AR 8100 (02662235)</td></tr><tr><td>AR 8100 (4775099)</td></tr>
                  <tr>
                      <td rowSpan={9}>접지저항측정기<br/>(SH-5050S)</td>
                      <td>SH 000022</td>
                      <td rowSpan={9}>9대</td>
                      <td rowSpan={9}><img src={gc} alt="접지저항측정기"/></td>
                    </tr>
                    <tr><td>SH 000024</td></tr><tr><td>SH 007205</td></tr><tr><td>SH 007234</td></tr><tr><td>SH 007250</td></tr>
                    <tr><td>SH 007387</td></tr><tr><td>SH 007403</td></tr><tr><td>SH 200701</td></tr><tr><td>SH 000199</td></tr>
                  <tr>
                      <td rowSpan={9}>절연저항측정기<br/>(DHE 8050-04)</td>
                      <td>15208969</td>
                      <td rowSpan={9}>9대</td>
                      <td rowSpan={9}><img src={lso} alt="절연저창측정기"/></td>
                    </tr>
                    <tr><td>15209588</td></tr><tr><td>15209853</td></tr><tr><td>15209864</td></tr><tr><td>15209870</td></tr>
                    <tr><td>15209877</td></tr><tr><td>E 2485656</td></tr><tr><td>E 2488515</td></tr><tr><td>608007</td></tr>
                  <tr>
                      <td rowSpan={9}>조도계 - NICETY<br/>(LX801)</td>
                      <td>16264094</td>
                      <td rowSpan={9}>9대</td>
                      <td rowSpan={9}><img src={lightmeter} alt="조도계"/></td>
                    </tr>
                    <tr><td>16264820</td></tr><tr><td>16264997</td></tr><tr><td>16264998</td></tr><tr><td>16265003</td></tr>
                    <tr><td>16265011</td></tr><tr><td>16265014</td></tr><tr><td>18132417</td></tr><tr><td>200502072</td></tr>
                    <tr>
                      <td>회전속도측정기<br/>(MS6208B)</td>
                      <td>BGK05799</td>
                      <td>1대</td>
                      <td><img src={ms6208b} alt="회전속도측정기"/></td>
                    </tr>
                    <tr>
                      <td>디지털거리측정기<br/>(GLM50)</td>
                      <td>502849898</td>
                      <td>1대</td>
                      <td><img src={dt9803} alt="디지털거리측정기"/></td>
                    </tr>
                    <tr>
                      <td>누전차단기 시험기<br/>(MSN300)</td>
                      <td>20160811-3374</td>
                      <td>1대</td>
                      <td><img src={msn300} alt="누전차단기 시험기"/></td>
                    </tr>
                    <tr>
                      <td>산업용내시경<br/>(GIC120C)</td>
                      <td>705001741</td>
                      <td>1대</td>
                      <td><img src={gic120c} alt="산업용내시경"/></td>
                    </tr>
                    <tr>
                      <td>청진기(비접촉식)</td>
                      <td>-</td>
                      <td>2대</td>
                      <td><img src={stethoscope} alt="청진기"/></td>
                    </tr>
                    <tr>
                      <td>가스누출검지기(방폭형)<br/>(MR-501Sid)</td>
                      <td>d06013</td>
                      <td>1대</td>
                      <td><img src={mr501sid} alt="가스누출검지기"/></td>
                    </tr>
                  </tbody>
              </table>
}
            </div>
        </div>
    </div>
  );
};

export default Equipment;
