import React from 'react'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";

const SafetyPaper = () => {
  //안전보건대장
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>안전보건대장</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">안전보건대장</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
          <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}}>발주자의 의무</th>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}} >설계자의 의무</th>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}}>시공사의 의무</th>

                            </tr>

                                <tbody style={{textAlign : "left"}}>

                                <tr>
                                    <td><h5 className="mt-3 font-bold">1. 공사계획 단계시 준비사항 (발주처 또는 건축주)</h5>
          <h5>가) 기본안전보건대장 작성 ( 대상: 공사금액 50억이상건설공사) <br/>
법규  :  산업안전보건법 제67조 시행규칙 제86조제4항<br/>
<span style={{color : "red"}}>(벌칙)  1천만원 이하의 과태료, 시행 2020년 1월16일이후 설계계약</span></h5>
          <h5 className="mt-3 font-bold">2. 기본안전보건대장 작성시 준비서류</h5>
          <h5>가) 공사명 , 공사기간, 공사금액, 담당자<br/>
   나) 개략적인 공사개요<br/>
   다) 공사별 위험요인 목록 </h5>
          <h5 className="mt-3 font-bold">3. 건설안전 전문가 선임</h5>
              <h5>가) 건설안전기술사 , 산업안전지도사<br/>
나) 건설안전 기사 자격 취득 후 3년이상 실무경력이 있는사람<br/>
다) 건설안전 산업기사 자격 취득 후 5년이상 실무경력이 있는사람<br/>
라) 전문가 선임</h5>

              <h5 className="mt-3 font-bold">4. 발주자 확인사항</h5>
              <h5>가) 공사시작후 매 3개월 마다 1회 이상 확인</h5></td>
                                    <td> <h5 className="mt-3 font-bold">1. 설계 단계시 준비사항(설계사무소)</h5>
              <h5>가) 설계안전보건대장 작성 ( 대상: 공사금액 50억이상건설공사)<br/>
         법규:  법규: 산업안전보건법 제67조 시행규칙 제86조제4항<br/>
        <span style={{color : "red"}}>(벌칙) 1천만원 이하의 과태료</span></h5>

              <h5 className="mt-3 font-bold">2. 설계안전보건대장 작성시 준비서류</h5>
              <h5>가) 관계도면 및 공사금액, 담당자<br/>
   나) 예정공정표<br/>
   다) 공종별 위험요인 및 안전대책수립<br/>
   라) 위험요인별 저감대책 수립</h5></td>
                                    <td><h5 className="mt-3 font-bold">1. 공사 단계시 준비사항 ( 시공사)</h5>
              <h5>   가) 공사안전보건대장 작성 ( 대상: 공사금액 50억이상건설공사)<br/>
         법규:  법규: 산업안전보건법 제67조 시행규칙 제86조제4항<br/>
        <span style={{color : "red"}}>(벌칙) 1천만원 이하의 과태료</span></h5>

              <h5 className="mt-3 font-bold">2. 공사안전보건대장 작성시 준비서류</h5>
              <h5>   가) 기본안전보건대장, 설계안전보건대장 제공<br/>
   나) 제공된 안전보건대장을 반영하여 공사안전보건대장 작성<br/>
   다) 공종별 위험요인 및 안전대책수립<br/>
   라) 위험요인별 저감대책 수립</h5></td>
                                </tr>
                            </tbody>
        </table>
<hr className="border-gray-700"/>
          <div className="mt-3 w-full">
              <h4 className="text-2xl font-bold">각 주체별 작성비용 및 용역기간</h4>
              <hr className="border-gray-700"/>
                        <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}}>구분</th>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}}>기본안전보건대장</th>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}} >설계안전보건대장</th>
                                <th style={{color : "white", textAlign : "center", fontSize : "20px", height : "35px", "background" : "#1e90ff"}}>공사안전보건대장</th>

                            </tr>

                                <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>내용</td>
                                    <td>기본안전보건대장 작성</td>
                                    <td>설계안전보건대장 작성</td>
                                    <td>공사안전보건대장 작성</td>
                                </tr>

                                <tr>
                                    <td>기간</td>
                                    <td>10일</td>
                                    <td>15일</td>
                                    <td>15일</td>
                                </tr>
                                <tr>
                                    <td>준비자료</td>
                                    <td>공사명, 공사개요</td>
                                    <td>1)기본안전보건대장<br/>2)도면, 위험요인 안전대책, 저감대책 등</td>
                                    <td>1)기본안전보건대장<br/>2)관계도면</td>
                                </tr>
                                <tr>
                                    <td>작성시점</td>
                                    <td>설계사무소 계약전</td>
                                    <td>시공사 계약전</td>
                                    <td>공사 착공전</td>
                                </tr>

                            </tbody>
        </table>



          </div>
      </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default SafetyPaper
