import React from 'react'

import img from '../../../assets/img/manufacturinghazard.png'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const ManufacturingHazard = () => {
    //제조업 유해예방 관리계획서
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>제조업 유해위험방지계획서</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">제조업 유해위험방지계획서</h1>
      <hr className="border-gray-700"/>
        <div>
            <h4>개요</h4>
            <hr className="border-gray-700"/>
            <h5>재해발생 위험이 높은 업종의 사업장 또는 특정한 기계·기구 및 설비를 설치·이전하거나 그 주요 구조부분을 변경하는 경우
해당 사업주가 유해·위험방지계획서를 작성·제출하여 심사·확인을 받음으로써 사업장의 사전 안전성을 확보하기 위한 법정 제도</h5>
            <span>[ 관련근거 ] 산업안전보건법 제42조(유해·위험방지계획서의 작성·제출 등), 제43조(유해·위험방지계획서 이행의 확인 등)</span>
            <hr className="border-gray-700"/>
            <h4>제출대상 사업장</h4>
            <hr className="border-gray-700"/>
            <h5>1. 다음 13개 업종의 어느 하나에 해당하는 사업으로서 전기 계약용량이 300kW 이상인 사업의 사업주가 해당 제품생산 공정과
직접적으로 관련된 건설물·기계·기구 및 설비 등 일체를 설치·이전하거나 그 주요 구조부분을 변경할 때</h5>
            <table className="business_table2" style={{margin : "20px 0px 20px 0px", textAlign : "center"}}>
            <thead>
              <tr>
                  <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>업종코드</th>
                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>한국표준산업분류(중분류)</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
              <td>10***</td>
              <td>식료품 제조업</td>
              </tr>
            <tr>
                <td>2</td>
              <td>16***</td>
              <td>목재 및 나무제품 제조업</td>
              </tr>
            <tr>
                <td>3</td>
              <td>20***</td>
              <td>화학물질 및 화학제품 제조업</td>
              </tr>
            <tr>
                <td>4</td>
              <td>22***</td>
              <td>고무제품 및 플라스틱제품 제조업</td>
              </tr>
            <tr>
                <td>5</td>
              <td>23***</td>
              <td>비금속 광물제품 제조업</td>
              </tr>
            <tr>
                <td>6</td>
              <td>24***</td>
              <td>1차 금속 제조업</td>
              </tr>
            <tr>
                <td>7</td>
              <td>25***</td>
              <td>금속가공제품 제조업 : 기계 및 가구 제외</td>
              </tr>
            <tr>
                <td>8</td>
              <td>261**</td>
              <td>반도체 제조업</td>
              </tr>
            <tr>
                <td>9</td>
              <td>262**</td>
              <td>전자부품 제조업</td>
              </tr>
            <tr>
                <td>10</td>
              <td>29***</td>
              <td>기타 기계 및 장비 제조업</td>
              </tr>
            <tr>
                <td>11</td>
              <td>30***</td>
              <td>자동차 및 트레일러 제조업</td>
              </tr>
            <tr>
                <td>12</td>
              <td>32***</td>
              <td>가구 제조업</td>
              </tr>
            <tr>
                <td>13</td>
              <td>33***</td>
              <td>기타 제품 제조업</td>
              </tr>
            </tbody>
          </table>
            <h5>2.  모든 업종의 사업장에서 대통령령으로 정하는 5개 기계·기구 및 설비를 설치·이전하거나 그 주요 구조부분을 변경하려는 경우<br/>
1) 금속이나 그 밖의 광물의 용해로 2) 화학설비 3) 건조설비 4) 가스집합 용접장치<br/>
5) 허가대상·관리대상 유해물질 및 분진작업 관련 설비 국소배기장치(이동식 제외), 밀폐설비 및 전체 환기장치</h5>
            <hr className="border-gray-700"/>
            <h4>심사 및 확인 절차</h4>
            <hr className="border-gray-700"/>
            <img src={img}/>
            <hr className="border-gray-700"/>
            <h4>제출 및 안내 문의처</h4>
            <hr className="border-gray-700"/>
            <h5>1. 제출처 : 사업장 소재지 관할 한국산업안전보건공단 광역·지역본부 또는 지사</h5>
            <h5>2. 제출서류 : 신청양식  산업안전보건법 시행규칙  별지 제16호서식에 따른 유해·위험방지계획서 2부(1부는 가급적 전자문서로 제출)</h5>
            <h5>3. 제출시기 : 해당 작업시작 15일 전까지(“해당 작업시작”이란 계획서 제출대상 건설물·기계·기구 및 설비 등을 설치·이전하거나
            주요구조 부분을 변경하는 공사의 시작을 말하며, 대지정리 및 가설사무소 설치 등의 공사준비기간은 제외한다.
            다만, 기존공장, 임대공장, 아파트형공장 등 건설물이 이미 설치되어 있는 경우에는 생산설비 설치의 시작을 말함)</h5>
        </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default ManufacturingHazard
