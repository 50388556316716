import React from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";

const ContractMakeForm = (props) => {
    const {company, agent, orderer, office_address, construction_name, industrial_number, business_number, location, phone, orderer_address, term_start, term_end,
    construction_start, construction_end, construction_phone, technical_class, technical_price, technical_number, construction_price, accrued_price, contract_date, print_number, remark, contract_url} = props.inputs
    const {handleChange, handleSetData, handleSave} = props
    const {disable} = props
    return (
                      <Card.Body>
                          <div>
                  <Button
                      size="sm"
                    variant="primary"
                    onClick={handleSave}
                      style={disable ? {display : "none"} : {}}
                  >기술지도계약서 저장</Button>
                </div>
                <Form>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>건설업체명*</label>
                        <Form.Control
                            type="text"
                            name="company"
                            value={company || ''}
                            onChange={handleChange}
                            disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>대표자*</label>
                        <Form.Control
                          type="text"
                          name="agent"
                            value={agent || ''}
                            onChange={handleChange}
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          본사주소
                        </label>
                        <Form.Control
                          type="text"
                          name="office_address"
                            value={office_address || ''}
                            onChange={handleChange}
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>산재관리번호</label>
                        <Form.Control
                          name="industrial_number"
                            value={industrial_number || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>사업개시번호</label>
                        <Form.Control
                          name="business_number"
                            value={business_number || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>공사명*</label>
                        <Form.Control
                          name="construction_name"
                            value={construction_name || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>소재지*</label>
                        <Form.Control
                          name="location"
                            value={location || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>전화번호</label>
                        <Form.Control
                          name="phone"
                            value={phone || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>현장대리인연락처</label>
                        <Form.Control
                          name="construction_phone"
                            value={construction_phone || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>공사금액*</label>
                        <Form.Control
                          name="construction_price"
                            value={construction_price || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>계상된 산업안전보건 관리비</label>
                        <Form.Control
                          name="accrued_price"
                            value={accrued_price || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>발주자*</label>
                        <Form.Control
                          name="orderer"
                            value={orderer || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>발주자 주소</label>
                        <Form.Control
                          name="orderer_address"
                            value={orderer_address || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="8">
                      <Form.Group>
                        <label>공사기간</label>
                        <Form.Control style={{marginBottom : "10px"}}
                          type="date"
                                      placeholder="2021-01-01"
                            name="construction_start"
                            value={construction_start || ''}
                            onChange={handleChange}
                                      disabled={disable}
                        ></Form.Control>
                          <Form.Control
                          type="date"
                          placeholder="2021-01-02"
                            name="construction_end"
                            value={construction_end || ''}
                            onChange={handleChange}
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                  </Row>
                  <hr />

                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>기술지도 구분*</label>
                        <Form.Control
                          value="건설공사"
                          disabled
                          type="text"

                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>기술지도 대가*</label>
                        <Form.Control
                          name="technical_price"
                            value={technical_price || ''}
                            onChange={handleChange}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>기술지도 횟수*</label>
                        <Form.Control
                            placeholder={0}
                          type="number"
                          name="technical_number"
                            value={technical_number || ''}
                            onChange={handleChange}
                            disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>

                    <Col className="pr-1" md="8">
                      <Form.Group>
                        <label>계약기간*</label>
                        <Form.Control style={{marginBottom : "10px"}}
                          type="date"
                                      placeholder="2021-01-01"
                            name="term_start"
                            value={term_start || ''}
                            onChange={handleChange}
                                      disabled={disable}
                        ></Form.Control>
                          <Form.Control style={{marginBottom : "10px"}}
                          type="date"
                                        placeholder="2021-01-02"
                            name="term_end"
                            value={term_end || ''}
                            onChange={handleChange}
                                        disabled={disable}
                        ></Form.Control>
                        <Button onClick={handleSetData} className="btn-fill" size="sm" variant="primary">공사기간과 동일</Button>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>발행일자*</label>
                        <Form.Control
                          type="date"
                          placeholder="2021-01-01"
                            name="contract_date"
                            value={contract_date || ''}
                            onChange={handleChange}
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>비고</label>
                        <Form.Control
                          cols="80"
                          placeholder="비고를 입력해주십시오."
                          rows="4"
                          as="textarea"
                          name="remark"
                            value={remark || ''}
                            onChange={handleChange}
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
    )
}
ContractMakeForm.defaultProps = {
  disable : false
}
export default ContractMakeForm;