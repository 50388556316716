import React from 'react';

const HomeFooter = () => {
    return (
        <footer className="h_footer">
          <ul>
            <li>경기도 안산시 단원구 원포공원 1로 30, 205호 ( 초지동, 서해프라자 )</li>
            <li>TEL) 031-405-7833</li>
            <li>FAX) 031-405-7835</li>
          </ul>
          <address>COPYRIGHTⓒ 2021 KOREA INDUSTRIAL SAFETY INSTITUTE CO., LTD. ALL RIGHTS RESERVED.</address>
        </footer>
    )
}
export default HomeFooter