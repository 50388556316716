/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Contracts from "views/admin/contract/Contracts.js";
import Users from "views/admin/user/Users";
import ReferenceMake from "views/admin/reference/ReferenceMake";
import CompleteContracts from "./views/admin/completecontract/CompleteContracts";

const dashboardRoutes = [
  /*
  {
    path: "/contracts",
    name: "기술지도계약서",
    icon: "nc-icon nc-notes",
    component: Contracts,
    layout: "/admin",
  },
  {
    path: "/completecontracts",
    name: "완료증명서",
    icon: "nc-icon nc-notes",
    component: CompleteContracts,
    layout: "/admin",
  },

  {
    path: "/users",
    name: "직원관리",
    icon: "nc-icon nc-circle-09",
    component: Users,
    layout: "/admin",
  },
*/

  {
    path: "/typography",
    name: "게시글생성",
    icon: "nc-icon nc-paper-2",
    component: ReferenceMake,
    layout: "/admin",
  },/*
  {
    path: "/table",
    name: "Table List",
    icon: "nc-icon nc-notes",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-paper-2",
    component: Icons,
    layout: "/admin",
  },

  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
  },*/
];

export default dashboardRoutes;
