import React from 'react'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";

const SafetyPlan = () => {
  //안전관리계획서
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>안전관리계획서</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
                <div className="container px-3">
                  <h1 className="text-3xl font-bold">안전관리계획서</h1>
                  <hr className="border-gray-700"/>
                    <div>
                        <h4>개요</h4>
                        <hr className="border-gray-700"/>
                        <h5>건설기술진흥법에 따라 건설공사의 안전관리계획의 실효성을 높이고
                        안전관리계획을 통한 건설현장 안전사고 예방과 시설물의 안전을 확보하기 위하여 시설물의
                        안전관리에 관한 특별법 대상 1,2종 시설물을 포함하는 건설공사의 안전관리계획서는 한국시설안전공단에서 전담하여 검토하도록 하는 제도
                        </h5><span>(건설공사 및 시설물 안전의 실효성을 담보하기 위한 제도)</span>
                        <hr className="border-gray-700"/>
                        <h4>1. 안전관리계획서를 수립해야 하는 건설공사</h4>
                        <hr className="border-gray-700"/>
                        <h5><strong>건설기술진흥법 시행령 제98조(안전관리계획의 수립)</strong>에 따라 다음과 같은 건설공사는 안전관리계획을 수립해야 한다.</h5>


                        <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>대상공사</th>
                            </tr>
                            <tbody>
                                <tr>
                                    <td>시설물의 안전 및 유지관리에 관한 특별법에 따른 1종 및 2종 시설물의 건설공사</td>
                                </tr>
                                <tr>
                                    <td>지하 10미터 이상을 굴착하는 건설공사</td>
                                </tr>
                            <tr>
                                    <td>폭발물을 사용하는 건설공사로서 20미터 안에 시설물이 있거나, 100미터 안에 사육하는 가축이 있어 해당 공사로 인한 영향을 받을 것이 예상되는 건설공사</td>
                                </tr>
                            <tr>
                                    <td>10층 이상 16층 미만인 건축물의 건설공사</td>
                                </tr>
                            <tr>
                                    <td>10층 이상인 건축물의 리모델링 또는 주택법에 따른 수직증축형 리모델링</td>
                                </tr>
                            <tr>
                                    <td>건설기계관리법에 등록된 다음 건설기계가 사용되는 건설공사 <br/>1. 천공기(높이 10미터 이상인 것만 해당)<br/>2. 향타 및 향발기<br/>3. 타워크레인</td>
                                </tr>
                            <tr>
                                    <td>다음 각 호의 가설구조물을 사용하는 건설공사<br/>
                                        1. 높이가 31미터 이상인 비계<br/>
                                        2. 작업발판 일체형 거푸집 또는 높이가 5미터 이상인 거푸집 및 동바리<br/>
                                        3. 터널의 지보공 또는 높이가 2미터 이상인 흙막이 지보공<br/>
                                        4. 동력을 이용하여 움직이는 가설구조물<br/>
                                        5. 발주자 또는 인허가기관의장이 필요하다고 인정하는 가설구조물
                                    </td>
                                </tr>
                            <tr>
                                    <td>발주자가 안전 관리가 특히 필요하다고 인정하는 건설공사<br/>
                                        지방자치단체의 조례로 정하는 건설공사 중 인허가기관의장이 안전 관리가 특히 필요하다고 인정하는 건설공사
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                         <hr className="border-gray-700"/>
                        <h4>2. 안전관리계획서 검토의뢰</h4>
                         <hr className="border-gray-700"/>
                        <h5>건설기술진흥법 시행령 제98조 (안전관리계획의 수립) 발주자나 인허가기관의장이 제3항에 따라 안전관리계획의 내용을 심사하는 경우에는 제100조 제2항에 따른
                        건설안전점검기관에 의뢰하여 검토하게 할 수 있다. 다만, 시설물의 안전관리에 관한 특별법 제2조 제2호 및 제3호에 따른 1종 시설물 및 2종
                        시설물의 건설공사의 경우에는 한국시설안전공단에 안전관리계획의 검토를 의뢰하여야 한다.</h5>
                         <hr className="border-gray-700"/>
                        <h4>제출서류</h4>
                         <hr className="border-gray-700"/>
                        <h5>1. 검토의뢰 공문 (발주청 또는 인허가기관의장)</h5>
                        <h5>2. 당해 건설공사의 안전관리계획서</h5>
                         <hr className="border-gray-700"/>
                        <h4>4. 행정 절차 (건설기술진흥법)</h4>
                         <hr className="border-gray-700"/>
                        <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>시공사</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>관계기관</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>공단</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>주요 내용</th>
                            </tr>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>1단계</td>
                                    <td>안전관리계획서<br/>심사요청</td>
                                    <td>계획서 접수</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            <tr>
                                    <td>2단계</td>
                                    <td></td>
                                    <td>검토의뢰</td>
                                    <td>검토의뢰 접수</td>
                                    <td>구비서류 등 <br/>(안전관리계획서, 검토비용)</td>
                                </tr>
                            <tr>
                                    <td>3단계</td>
                                    <td></td>
                                    <td>심사</td>
                                    <td>검토 결과 <br/>의견서 통보</td>
                                    <td></td>
                                </tr>
                            <tr>
                                    <td>4단계</td>
                                    <td>안전관리 시행 또는 지적 내용 보안</td>
                                    <td>심사결과 통보</td>
                                    <td></td>
                                    <td>판정 결과 <br/>(적정, 조건부 적정, 부적정)</td>
                                </tr>
                            <tr>
                                    <td>5단계</td>
                                    <td>보완내역<br/> 재심사 요청</td>
                                    <td>보완계획서 접수</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            <tr>
                                    <td>6단계</td>
                                    <td></td>
                                    <td>재검토 의뢰</td>
                                    <td>재검토 의뢰 접수</td>
                                    <td>보완 완전관리계획서</td>
                                </tr>
                            <tr>
                                    <td>7단계</td>
                                    <td></td>
                                    <td>재심사</td>
                                    <td>재검토 결과<br/>의견서 통보</td>
                                    <td></td>
                                </tr>
                            <tr>
                                    <td>8단계</td>
                                    <td>건설현장<br/>안전관리 시행</td>
                                    <td>재심사 결과 통보</td>
                                    <td></td>
                                    <td>판정 결과 <br/>(적정, 조건부 적정, 부적정)</td>
                                </tr>
                            <tr>
                                <td colSpan={5}>공단의 안전관리계획서 검토 시작일은 검토의뢰 공문, 안전관리계획서 도착 및 검토비용 납부가 완료되는 시점부터 입니다.</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
      </div>
      </div>
  )
}
export default SafetyPlan
