import React, {useState} from 'react';
import {Link} from "react-router-dom";

const ContentsNavbar = () => {
    const [show, setShow] = useState([false, false, false])
    const showCategory = (e) => {
        const { index } = e.target.dataset;
        let _show = show.slice();
        _show[index] = !_show[index];
        setShow(_show)
    }
    return (
        <div className='sub_list'>
            <div>
                <ul data-index='0' onClick={showCategory}>건설/재해예방기술지도</ul>
                {show[0] ? <div>
                    <li><Link to="/business/technicalguidance">재해예방기술지도</Link></li>
                    <li><Link to="/business/constructionsafety">건설현장 안전컨설팅</Link></li>
                    <li><Link to="/business/constructionhazard">건설업 유해위험방지계획서</Link></li>
                    <li><Link to="/business/safetyplan">안전관리계획서</Link></li>
                    <li><Link to="/business/safetypaper">안전보건대장</Link></li>
                </div> : ""}
            </div>
            <div>
                <ul data-index='1' onClick={showCategory}>제조/안전관리자 위탁</ul>
                {show[1] ?<div>
                    <li><Link to="/business/safetymanagement">안전관리자 위탁</Link></li>
                    <li><Link to="/business/manufacturinghazard">제조업 유해위험방지계획서</Link></li>
                    <li><Link to="/business/psm">공정안전보고서(PSM)</Link></li>
                    <li><Link to="/business/safetyconsulting">안전교육 컨설팅</Link></li>
                    <li><Link to="/business/musculoskeletal">근골격계 유해요인 조사</Link></li>
                </div>:""}
            </div>
            <div style={{borderBottom : '1px solid #ccc'}}>
                <ul data-index='2' onClick={showCategory}>안전진단</ul>
                {show[2]?
                    <div>
                    <li><Link to="/business/adjacent">인접지 사전조사</Link></li>
                    <li><Link to="/business/regular">제3종 시설물 정기점검</Link></li>
                    <li><Link to="/business/safetycheck">시특법 안전점검</Link></li>
                </div>:""}
            </div>
        </div>
    )
}
export default ContentsNavbar;