import React from 'react'

import img from '../../../assets/img/business/part_3_con.gif'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const SafetyConsulting = () => {
    //안전점검 컨설팅
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>안전교육컨설팅</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">안전교육 컨설팅</h1>
      <hr className="border-gray-700"/>

      <div className="mt-8">
        <table border="0" cellSpacing="0" cellPadding="0" width="700">
          <tbody>
          <tr>
            <td width="700">
              <table border="0" cellSpacing="0" cellPadding="0" width="700">
                <tbody>
                <tr>
                  <td valign="top" width="700">
                    <table border="0" cellSpacing="0" cellPadding="0" width="700">
                      <tbody>
                      <tr>
                        <td height="25" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>제도 및 관련법규</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td><strong><font color="#000000">○제도 </font></strong><br/>- 자율 안전관리 능력이 부족한 중소사업장의
                                      취약계층 근로자에 대해 사업장 실정에 적합한 종합적이고 체계적인 <br/>안전보건교육을 지원함으로써 산업재해예방을 위한
                                      제도 <br/><br/><font color="#000000"><strong>○관련법규</strong></font> <br/>- 산업안전보건법
                                      제31조 및 산업안전보건법 시행규칙 제33조의 규정에 의거 사업주는 당해 사업장의 근로자에 <br/>대하여 노동부령이 정하는 바에 의하여 정기적으로
                                      안전ㆍ보건에 관한 교육을 실시하여야 한다. <br/>- 산업안전보건법 제31조 제1항 내지 제3항의 규정에 의하여 사업주가 근로자에 대하여
                                      실시하여야하는 교육시간 <br/>(산업안전보건법시행규칙 별표 8)과 교육내용 (산업안전보건법시행규칙 별표 8의 2)은 다음과 같다.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td bgcolor="#cccccc">
                                      <table border="0" cellSpacing="1" cellPadding="6" width="100%">
                                        <tbody>
                                        <tr align="center" bgcolor="#ecffff">
                                          <td><font color="#226868"><strong>구 분</strong></font></td>
                                          <td><font color="#226868"><strong>교 육 과 정</strong></font></td>
                                          <td><font color="#226868"><strong>교 육 대 상</strong></font></td>
                                          <td><font color="#226868"><strong>교 육 시 간</strong></font></td>
                                          <td><font color="#226868"><strong>비 고</strong></font></td>
                                        </tr>
                                        <tr align="center" bgcolor="#ffffff">
                                          <td rowSpan="6">사업내<br/>안전보건<br/>교육<br/>(법제31조)</td>
                                          <td rowSpan="3">정기 교육</td>
                                          <td>관리감독자의<br/>지위에 있는 자</td>
                                          <td>반기 8시간 또는 연간 16시간 이상<br/>또는 지정교육기관위탁(16시간)</td>
                                          <td></td>
                                        </tr>
                                        <tr align="center" bgcolor="#ffffff">
                                          <td>생산직 근로자</td>
                                          <td>매월 2시간 이상(또는 분기 6시간 이상)</td>
                                          <td></td>
                                        </tr>
                                        <tr align="center" bgcolor="#ffffff">
                                          <td>사무직 근로자</td>
                                          <td>매월 1시간 이상(또는 분기 3시간 이상)</td>
                                          <td></td>
                                        </tr>
                                        <tr align="center" bgcolor="#ffffff">
                                          <td>채용시 교육</td>
                                          <td>신규채용자</td>
                                          <td>8시간(건설업 1시간 이상) 이상</td>
                                          <td></td>
                                        </tr>
                                        <tr align="center" bgcolor="#ffffff">
                                          <td>작업내용변경시 교육</td>
                                          <td>작업내용변경자</td>
                                          <td>2시간(건설업 1시간 이상) 이상</td>
                                          <td></td>
                                        </tr>
                                        <tr align="center" bgcolor="#ffffff">
                                          <td>특별 교육</td>
                                          <td>산안법 시행규칙<br/>별표8의2 제1호 <br/>라목 종사자</td>
                                          <td>16시간(건설업 2시간 이상) 이상</td>
                                          <td>39개<br/>작업<br/>분야</td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="50" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>대상사업장 및 교육내용</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td>
                                      <div><strong><font color="#000000">○대상사업장 </font></strong><br/>- 자율 안전관리 능력이 부족한
                                        중소사업장 <br/>- 전문적인 안전보건교육을 필요로하는 사업장<br/>- 사업장 : 근로자를 1인 이상 고용하고 고용보험에 가입한 사업장 -
                                        근로자 : 모든 근로자 <br/><br/><font color="#000000"><strong>○교육내용</strong></font> <br/>-
                                        근로자 안전보건 교육<br/>- 산업재해 예방 교육<br/>- 근골격계질환 예방 교육 <br/>- 직장내 성희롱 예방 교육 <br/></div>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="50" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>안전관리위탁 대상</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td><strong><font color="#000000">○안전관리자 위탁 대상 사업장</font></strong> <br/>산업안전보건법 제15조
                                      영별표3에 해당하는 업종의 사업장으로 안전관리자의 선임 의무가 있는 상시 근로자 50인 이상 사용 사업장 <br/><br/><strong><font
                                          color="#000000">○안전컨설팅 사업장</font></strong> <br/>법적 안전관리자의 선임 유ㆍ무 및 근로자수와 관계 없이
                                      자체적으로 안전관리 업무를 수행하기가 어려운 사업장 및 보다 전문적인 안전관리가 필요한 사업장
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td height="50" width="700"></td>
                      </tr>

                      <tr>
                        <td width="700"><font color="#64700d"><strong>◎</strong></font>&nbsp;<font
                            color="#64700d"><strong>위탁시 효과</strong></font></td>
                      </tr>
                      <tr>
                        <td height="10" width="700"></td>
                      </tr>
                      <tr>
                        <td width="700">
                          <table border="0" cellSpacing="0" cellPadding="0" width="700">
                            <tbody>
                            <tr>
                              <td background="../images/guide/guide01_19.gif" width="700" align="center">
                                <table border="0" cellSpacing="0" cellPadding="0" width="700">
                                  <tbody>
                                  <tr>
                                    <td width="700"><font color="#000000"><strong>○위탁을 통해 교육을 이수한 근로자 및 관리감독자는 산업안전보건법
                                      제31조에 의한 안전보건교육면제</strong></font> <br/>- 산업안전보건법시행규칙 제33조의 2(안전ㆍ보건교육의
                                      면제)<br/><font color="#ff6600">노동부장관은 사업주가 관리감독자에 대하여 노동부장관이 따로 정하는 교육을 이수하게 한 때에는
                                        당해연도의 <br/>법 제31조 제1항의 규정에 의한 정기적인 안전보건교육을 면제할 수 있다.</font> <br/><br/><font
                                          color="#000000"><strong>○사업장 실정에 적합한 종합적이고 체계적인 안전ㆍ보건교육을 지원함으로써 산업재해예방에
                                        기여</strong></font> <br/><strong><font color="#000000"><br/>○사업장 직접 방문 교육으로 인한
                                        근로자 시간 절약 및 편리성 </font></strong><br/><br/><font color="#000000"><strong>○사업장 실정에
                                        맞게 설계된 교육프로그램으로 교육효과 극대화</strong></font></td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td height="20" width="700"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default SafetyConsulting
