import React from 'react'
import part_3_con_01 from '../../../assets/img/business/part_3_con_01.gif';
import part_3_con_02 from '../../../assets/img/business/part_3_con_02.gif';
import part_3_con_03 from '../../../assets/img/business/part_3_con_03.gif';
import part_3_con_04 from '../../../assets/img/business/part_3_con_04.gif';
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";
const SafetyCheck = () => {
  //시특법 안전점검
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a >사업분야</a></li>
                <li><a>시특법 안전점검</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}
            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
    <div className="container px-3">
      <h1 className="text-3xl font-bold">시특법 안전점검</h1>
      <hr className="border-gray-700"/>
      <div>
        <h4>1. 정밀안전점검</h4>
        <hr className="border-gray-700"/>
                    <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                                <th colSpan={2} style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>내용</th>
                            </tr>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>관련법</td>
                                    <td colSpan={2}>1. 시설물의 안전 및 유지관리에 관한 특별법 (시특법) 제6조 「안전점검의 실시」<br/>
                                        2. 동 시행령 제6조 「안전점검의 실시」
                                    </td>
                                </tr>
                            <tr>
                                <td>목적</td>
                                <td colSpan={2}>시설물의 현 상태를 정확히 판단하고 최초 또는 이전에 기록된 상태로부터의 변화를 확인하며 구조물이 현재의 사용 요건을 계속 만족시키고 있는지 확인하는데 있음.</td>
                            </tr>
                            <tr>
                                <td>점검시기</td>
                                <td colSpan={2}>※ 건축물<br/>
                                ● A등급 : 4년에 1회 이상<br/>
                                ● B~C등급 : 3년에 1회 이상<br/>
                                ● D~E등급 : 2년에 1회 이상<br/>
                                ※ 그 외 시설물<br/>
                                ● A등급 : 3년에 1회 이상<br/>
                                ● B~C등급 : 2년에 1회 이상<br/>
                                ● D~E등급 : 1년에 1회 이상</td>
                            </tr>

                            <tr>
                                <td>점검방법</td>
                                <td colSpan={2}>면밀한 육안점검과 측정기를 이용한 점검
                                </td>
                            </tr>

                            <tr>
                                <td rowSpan={2}>점검사항</td>
                                <td>
                                  1종시설물
                                </td>
                                <td>
                                  2종시설물
                                </td>

                            </tr>
                            <tr>
                                <td>
                                  1. 자료수집 및 분석<br/>
                                  2. 현장조사 및 시험<br/>
                                  3. 상태평가<br/>
                                  4. 안전성평가<br/>
                                  5. 보수·보강방법 제시<br/>
                                  6. 보고서 작성
                                </td>
                                <td>
                                  1. 자료수집 및 분석<br/>
                                  2. 현장조사 및 시험<br/>
                                  3. 안전성평가
                                </td>

                            </tr>

                            </tbody>
                        </table>
        <hr className="border-gray-700"/>
        <h4>2. 정기안전점검</h4>
        <hr className="border-gray-700"/>
                    <table className="business_table2" style={{width : "100%"}}>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                                <th colSpan={2} style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>내용</th>
                            </tr>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>관련법</td>
                                    <td colSpan={2}>1. 시설물의 안전 및 유지관리에 관한 특별법 (시특법) 제6조 「안전점검의 실시」<br/>
                                        2. 동 시행령 제6조 「안전점검의 실시」
                                    </td>
                                </tr>
                            <tr>
                                <td>목적</td>
                                <td colSpan={2}>경험과 기술을 갖춘 자에 의한 세심한 육안검사 수준의 점검으로서 시설물의 기능적 상태를 판단하고 시설물이 현재의 사용요건을 계속 만족시키고 있는지 확인</td>
                            </tr>
                            <tr>
                                <td>점검시기</td>
                                <td colSpan={2}>
                                  반기별 1회 이상<br/>
                                  ※안전등급에 따라 변동될 수 있음.
                                </td>
                            </tr>

                            <tr>
                                <td>점검방법</td>
                                <td colSpan={2}>순찰과 유사한 육안검사
                                </td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>점검사항</td>
                                <td>
                                  1종시설물
                                </td>
                                <td>
                                  1. 공동주택 외의 건축물로서 21층 이상 또는 연면적 5만㎡ 이상의 건축물<br/>
                                  2. 연면적 1만㎡ 이상의 지하도상가 (지하보도면적 포함)
                                </td>

                            </tr>
                            <tr>
                                <td>
                                  2종 시설물
                                </td>
                                <td>
                                  1. 16층 이상의 공동주택<br/>
                                  2. 1종 시설물에 해당하지 않는 공동주택 외의 건축물로서 16층 이상 또는 연면적 3만㎡ 이상의 건축물<br/>
                                  3. 1종 시설물에 해당하지 않는 건축물로서 연면적 5천㎡ 이상의 문화 및 집회시설, 종교시설, 판매시설, 운수시설 중 관광숙박시설 및 관광 휴게시설<br/>
                                  4. 1종 시설물에 해당하지 않는 철도 역시설로서 고속철도, 도시철도 및 광역철도 역시설<br/>
                                  5. 1종 시설물에 해당하지 않는 지하도상가로서 연면적 5천㎡ 이상의 지하도상가 (지하보도면적 포함)<br/>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                  3종 시설물
                                </td>
                                <td>
                  1. 준공 후 15년이 경과 된 5층 이상~15층 이하 아파트<br/>
                  2. 준공 후 15년이 경과 된 연면적 660㎡ 초과, 4층 이하 연립주택<br/>
                  3. 준공 후 15년이 경과 된 연면적 1천㎡ 이상~5천㎡ 미만의 <br/>판매시설, 숙박시설, 운수시설, 문화 및 집회시설, 의료시설, 장례식장, 종교시설, 위락시설, 관광휴게시설, 수련시설, 노유자시설, 운동시설, 교육시설<br/>
                  4. 준공 후 15년이 경과 된 연면적 5백㎡ 이상~1천㎡ 미만의 문화 및 집회시설 중 공연장 및 집회장, 종교시설, 운동시설<br/>
                  5. 준공 후 15년이 경과 된 연면적 3백㎡ 이상~1천㎡ 미만의 위락시설, 관광휴게시설<br/>
                  6. 준공 후 15년이 경과 된 11층 이상~16층 미만의 위락시설 또는 연면적 5천㎡ 이상~3만㎡ 미만의 건축물<br/>
                  7. 5천㎡ 미만의 상가가 설치된 지하도상가 (지하보도면적 포함)<br/>
                  8. 준공 후 15년이 경과 된 연면적 1천㎡ 이상의 공공청사
                                </td>

                            </tr>

                            </tbody>
                        </table>
      </div>
    </div>
            </div>
      </div>
      </div>
  )
}
export default SafetyCheck
