import React, {useState, useEffect} from "react";
import {Accordion, Button, Card, Col, Container, Form, Row, Modal} from "react-bootstrap";
import {check_params, parse_krw} from "../../../utils";
import contract_api from '../../../api/ContractAPI';
import {useHistory} from "react-router";
import axios from 'axios'
import ContractForm from "../../../components/Contract/ContractForm";
import ComplelteContractForm from "../../../components/Contract/CompleteContractForm.js";
import Loading from "../../../components/Loading";
const Contract = ({match}) => {
    let contract_id = match.params.id
    //const server = "http://18.179.138.213:8000"
    const server = "http://18.179.138.213:3001"
    const check_number_value = ["technical_price", "construction_price", "accrued_price"]
    const [complete, setComplete] = useState({})
    contract_id = check_params(contract_id)
    const history = useHistory()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [inputs, setInputs] = useState({
    company : '',
    agent : '',
    orderer : '',
    office_address : '',
    construction_name : '',
    industrial_number : '',
    business_number : '',
    location : '',
    phone : '',
    orderer_address : '',
    term_start : '',
    term_end : '',
    construction_start : '',
    construction_end : '',
    technical_class : '',
    technical_price : 0,
    technical_number : 0,
    construction_price: 0,
    accrued_price: 0,
    contract_date: "",
    username: "",
    contract_url : "",
    remark : "",
    construction_phone : ""
  })
  const {company, agent, orderer, office_address, construction_name, industrial_number, business_number, location, phone, orderer_address, term_start, term_end,
  construction_start, construction_end, construction_phone, technical_class, technical_price, technical_number, construction_price, accrued_price, contract_date, remark, contract_url} = inputs

  const [showModal, setShowModal] = useState(false);
  const [completedate, setCompletedate] = useState("")
  const [teachdates, setTeachdates] = useState([])
  const [teach, setTeach] = useState("")
  const [completeremark, setCompleteremark] = useState("")
  // complete_id -> isNew
  const [isNew, setIsNew] = useState(0)
  const [loading, setLoading] = useState(false)
  const [complete_url, setComplete_url] = useState("")
  const handleCheck = (complete) => {
    return Object.keys(complete).length === 0 && complete.constructor === Object
  }
  useEffect(() => {
    get_contract_data()



  }, [])

  const get_contract_data = async () => {
    try {
      setLoading(true)
      let token = sessionStorage.getItem('token')
      let user_id = sessionStorage.getItem('id')

      let post_data = {token : token, user_id : user_id, contract_id : contract_id}
      await axios.post(
        server + "/contract", post_data
      ).then((response) => {
        let datas = response.data

        if (datas['response'] === 200){
          let data = datas['datas'][0]

          try{
            data['construction_price'] = parse_krw(data['construction_price'])
            data['accrued_price'] = parse_krw(data['accrued_price'])
            data['technical_price'] = parse_krw(data['technical_price'])
          }catch (e) {
          }

          if (data.hasOwnProperty("complete")){
            try{
              if (data['complete'] == null || handleCheck(data['complete'])) {
              }else{
                let complete = data['complete']
                setIsNew(complete['id'])
                setCompletedate(complete['date'])
                setCompleteremark(complete['remark'])
                setComplete_url(complete['complete_url'])

                try{
                  let date_list = complete['lead_date'].split(",")
                  date_list = date_list.slice(0, -1)
                  setTeachdates(date_list)
                }catch (err){

                }
              }
            }catch (e) {
            }
          }

          setInputs({
            company : data['company'],
            agent : data['agent'],
            orderer : data['orderer'],
            office_address : data['office_address'],
            construction_name : data['construction_name'],
            industrial_number : data['industrial_number'],
            business_number : data['business_number'],
            location : data['location'],
            phone : data['phone'],
            orderer_address : data['orderer_address'],
            term_start : data['term_start'],
            term_end : data['term_end'],
            construction_start : data['construction_start'],
            construction_end : data['construction_end'],
            technical_class : data['technical_class'],
            technical_price : data['technical_price'],
            technical_number : data['technical_number'],
            construction_price: data['construction_price'],
            accrued_price: data['accrued_price'],
            contract_date: data['contract_date'],
            username: data['username'],
            contract_url: data['contract_url'],
            remark: data['remark'],
            construction_phone: data['construction_phone']

          })
        }else{
          alert('데이터를 불러오는데 실패하였습니다.')

        }
      }).catch((err) => {
        alert('데이터를 불러오는데 실패하였습니다.')
      })

    } catch (e) {
    }finally {
      setLoading(false)
    }
  }
  const handleSetData = (e) => {
    e.preventDefault()
    setInputs({
      ...inputs,
      term_start: construction_start,
      term_end: construction_end
    })
  }
  const handleSave = async () => {
    if (construction_name === "" || agent === "" || company === "" || location === "" || construction_price === 0 || orderer === "" ||
    construction_start === "" || construction_end === "" || term_end === "" || term_start === "" || technical_class === "" || technical_number === 0 || technical_price === 0) {

      return alert("필수 항목을 입력하시오.")

    }
    try {
      let token = sessionStorage.getItem('token')
      let user_id = sessionStorage.getItem('id')
      let _inputs = inputs;
      delete _inputs.contract_url;
      let post_data = {
        token : token,
        user_id : user_id,
        contract_id : contract_id,
        contract : _inputs

      }
      await axios.post(
        server + "/updatecontract", post_data
      ).then((response) => {
        let datas = response.data
        if (datas['response'] == 200){
          alert("저장되었습니다.")
          window.location.replace('/admin/contract/' + contract_id)
        }else{
          alert("저장 실패하였습니다.")
        }
      }).catch((err) => {
      })
    }catch (err){
    }
  }

  const handleChange = (e) => {
    let {value, name} = e.target
    check_number_value.map(check_name => {
      if (check_name == name) {
        value = parse_krw(value)
      }
    })

    setInputs({
      ...inputs,
      [name] : value
    })
  }
  const handlePrint = async () => {
    if (contract_url === "" || contract_url === null) {
      let token = sessionStorage.getItem('token')
      let user_id = sessionStorage.getItem('id')
      let post_data = {token : token, user_id : user_id, contract_id : contract_id};
      await axios.post("/printcontract", post_data).then((response) => {
        let datas = response.data;
        if (datas['response'] === 200) {
          window.open(datas['contract_url'], "_blank")
        }else {
          alert('출력에 실패하였습니다.')
        }
      })
    }else {
      window.open(contract_url, "_blank")
    }
  }
  /* 완료 증명서 벨류 */

  const handleAddList = () => {
    if (teach === "") return alert("날짜를 입력하시오.")
    if (teachdates.length > 23 ) return alert("최대 지도횟수를 초과했습니다.")
    let newdate = teachdates.concat(teach)
    setTeachdates(newdate)
  }
  const handleRemove = (index) => {
    let newList = teachdates.filter((date, idx) => idx !== index)
    setTeachdates(newList)
  }
  const handleCompletePrint = () => {
    if (complete_url === "" || complete_url === null) {
      return alert("저장을 해주시기바랍니다.")
    }else {
      window.open(complete_url, "_blank")
    }
  }
  const handleCompleteSave = async() => {
    if (completedate == "") {return alert('발행날짜를 입력하시오.')}
      try {

        let date_objects = ""

        teachdates.map((date, idx) => {
          date_objects += date + ","
        })
        let token = sessionStorage.getItem('token')
          let user_id = sessionStorage.getItem('id')
        let post_data = {token : token, user_id : user_id, contract_id : contract_id,
            complete : {
              lead_date : date_objects,
              remark : completeremark,
              date : completedate
          }}
        if ( isNew != 0) {
          post_data.complete_id = isNew;

          await axios.post(server + "/completeupdate", post_data).then(response => {
            if (response.data['response'] == 200) {
              alert('수정이 완료되었습니다.')
              window.location.href = "/admin/contract/" + contract_id

            }else {
              alert('수정이 올바르게 되지 않았습니다.')
            }
          }).catch(err => {
            alert ( err + "\n 관리자에게 문의하세요")
          })
        }else {
          let post_data = {token : token, user_id : user_id, contract_id : contract_id,
            complete : {
              lead_date : date_objects,
              remark : completeremark,
              date : completedate
          }}
          await axios.post(server + "/completemake", post_data).then(response => {
            if (response.data['response'] == 200) {
              alert('저장이 완료되었습니다.')
              window.location.href = "/admin/contract/" + contract_id

            }else {
              alert('저장이 올바르게 되지 않았습니다.')
            }
          }).catch(err => {
            alert ( err + "\n 관리자에게 문의하세요")
          })
        }



      }catch (err){
        alert("저장 실패하였습니다.")
      }
  }
  const handleDelete = async() => {
    let token = sessionStorage.getItem('token')
    let user_id = sessionStorage.getItem('id')
    let post_data = {
      "token" : token,
      "user_id" : user_id,
      "contract_id" : contract_id
    }
    await axios.post(
        server + "/deletecontract", post_data
      ).then((response) => {
        if (response.status === 200){
          if (response.data.response === 200){
            alert("삭제 성공!")
            history.push("/admin/contracts")
          }else{
            alert("서버 상태를 확인해주세요")
          }
        }else{
          alert("네트워크 오류")
        }

    })
  }
    return (
        <>
          {loading ? <Loading/> : ""}
      <Container fluid>
        <Row>
          <Col md="12">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>기술지도계약서 삭제</Modal.Title>
                </Modal.Header>
              <Modal.Body><h5>정말로 삭제하시겠습니까?</h5></Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    닫기
                  </Button>
                  <Button variant="danger" onClick={handleDelete}>
                    삭제
                  </Button>
                </Modal.Footer>
            </Modal>
            <Accordion defaultActiveKey="0">
            <Card style={{marginBottom : "5px"}}>
              <Accordion.Toggle as={Card.Header} eventKey="0" className="d-flex show" style={{justifyContent : "space-between", padding : "15px"}}>
                <Card.Title as="h4" >기술지도 계약서</Card.Title>

              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <ContractForm
                  inputs={inputs}
                  handlePrint={handlePrint}
                  handleSave={handleSave}
                  handleChange={handleChange}
                  handleSetData={handleSetData}
                  handleShow={handleShow}
                />
              </Accordion.Collapse>
            </Card>
              </Accordion>
            <Accordion>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1" className="d-flex" style={{justifyContent : "space-between", padding : "15px"}}>
                  <Card.Title as="h4" >완료증명서</Card.Title>

                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <ComplelteContractForm
                     handleCompletePrint={handleCompletePrint}
                      handleCompleteSave={handleCompleteSave}
                    handleAddList={handleAddList}
                    handleRemove={handleRemove}
                    handleCompleteDate={(e) => setCompletedate(e.target.value)}
                    handleTeach={(e) => setTeach(e.target.value)}
                    handleCompleteRemark={(e) => setCompleteremark(e.target.value)}
                    completedate={completedate}
                    completeremark={completeremark}

                    teach={teach}
                    teachdates={teachdates}

                  />
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>

        </Row>
      </Container>
    </>
    )
}
export default Contract
