import React from 'react';
import {Spinner} from "react-bootstrap";

const Loading = () => {
    return (
        <div style={{ width : "100vw%", height : "100vh", backgroundColor : "#d9d9d9", position : "fixed", zIndex : 99999, opacity : 0.8}}>
        <div style={{ position : "fixed", top: "50%", left : "50%", transform : "translate(-50%, -50%)" }}>

            <h2>로딩중입니다.</h2>
            <Spinner animation="border" variant="primary" />
        </div>
        </div>
    )
}
export default Loading