import React from 'react';
import {Button, Card, Modal} from "react-bootstrap";

export default function SearchContract(props){
    /*const {company, agent, orderer, office_address, construction_name, industrial_number, business_number, location, phone, orderer_address, term_start, term_end,
    construction_start, construction_end, construction_phone, technical_class, technical_price, technical_number, construction_price, accrued_price, contract_date, print_number, remark, contract_url} = props.inputs

    */
    const {search, searchModel, contracts} = props;
    const {handleSearch, handleSearchClick, handleSearchModelOpen, handleSearchModelClose} = props;
    return (
            <Modal show={searchModel} onHide={handleSearchModelClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>기술지도 계약서를 선택해주세요.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input placeholder="공사명 또는 회사명을 입력하세요" onChange={handleSearch} value={search}/>
                    </div>
                    <div className="complete-contract-list">
                        <ul>
                            {contracts && contracts.map((contract, idx) => {
                                return <li className="complete-contract-item" data-index={contract.id} key={idx} onClick={handleSearchClick}>{contract.construction_name}</li>
                            })}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSearchModelClose}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
    )
}