import React from 'react';
import HomeHeader from "../components/Navbars/HomeHeader";
import HomeFooter from "../components/Footer/HomeFooter";
import Intro from "../views/home/intro/Intro";
import History from "../views/home/intro/History"
import Organization from "../views/home/intro/Organization";
import GoogleMaps from "../views/home/intro/GoolgeMaps"
import Adjacent from "../views/home/business/Adjacent";
import ConstructionHazard from "../views/home/business/ConstructionHazard";
import ConstructionSafety from "../views/home/business/ConstructionSafety";
import ManufacturingHazard from "../views/home/business/ManufacturingHazard";
import Musculoskeletal from "../views/home/business/Musculoskeletal";
import PSM from "../views/home/business/PSM";
import Regular from "../views/home/business/Regular";
import SafetyCheck from "../views/home/business/SafetyCheck";
import SafetyConsulting from "../views/home/business/SafetyConsulting";
import SafetyManagement from "../views/home/business/SafetyManagement";
import SafetyPlan from "../views/home/business/SafetyPlan";
import TechnicalGuidance from "../views/home/business/TechnicalGuidance";
import SafetyPaper from "../views/home/business/SafetyPaper";
import Contact from "../views/home/Contact";
import References from "../views/home/References";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import {Route, Switch, Redirect, Router} from "react-router-dom";
import Home from "../views/home/Home";
import ReferenceView from "../views/home/ReferenceView";
import Research from "../views/home/research/Research";
import NotFoundPage from './NotFoundPage';
import Equipment from "../views/home/intro/equipment/Equipment";
import Manpower from "../views/home/intro/Manpower";


const Main = () => {

    return (
        <div>

            <HomeHeader/>
                <Switch>
                    <Route exact path="/intro/history" component={History}/>
                    <Route exact path="/intro/organization" component={Organization}/>
                    <Route exact path="/intro/maps" component={GoogleMaps}/>
                    <Route exact path="/intro/equipment" component={Equipment}/>
                    <Route exact path="/intro/manpower" component={Manpower}/>
                    <Route exact path="/intro" component={Intro}/>
                    <Route path="/business/adjacent" exact component={Adjacent} />
                    <Route path="/business/constructionhazard" exact component={ConstructionHazard} />
                    <Route path="/business/constructionsafety" exact component={ConstructionSafety} />
                    <Route path="/business/manufacturingHazard" exact component={ManufacturingHazard} />
                    <Route path="/business/musculoskeletal" exact component={Musculoskeletal} />
                    <Route path="/business/psm" exact component={PSM} />
                    <Route path="/business/regular" exact component={Regular} />
                    <Route path="/business/safetycheck" exact component={SafetyCheck} />
                    <Route path="/business/safetyconsulting" exact component={SafetyConsulting} />
                    <Route path="/business/safetymanagement" exact component={SafetyManagement} />
                    <Route path="/business/safetyplan" exact component={SafetyPlan} />
                    <Route path="/business/technicalguidance" exact component={TechnicalGuidance} />
                    <Route path="/business/safetypaper" exact component={SafetyPaper}/>
                    <Redirect from="/business" to="/business/adjacent" />
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/references/:category" component={References}/>
                    <Redirect exact from="/references" to="/references/construction" />
                    <Route exact path="/reference/:id" component={ReferenceView}/>
                    <Redirect from="/reference" to="/references/construction" />
                    <Route exact path="/research/:company" component={Research}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/" component={Home}/>
                    <Route path={"*"} component={NotFoundPage}/>

                </Switch>
            <HomeFooter/>
        </div>
    )
}
export default Main;