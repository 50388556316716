import React from 'react'
import ContentsNavbar from "../../../components/Navbars/ContentsNavbar";


const PSM = () => {
  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
            <div className="sub_title_banner">
              <h2 className="tit">비지니스</h2>
            </div>
            {/*--// 타이틀 --*/}
            {/*-- 리본바 --*/}
            <div className="sub_reborn">
              <ul>
                <li><a>홈</a></li>
                <li><a>사업분야</a></li>
                <li><a>공정안전보고서(PSM)</a></li>
              </ul>
            </div>
            {/*--// 리본바 --*/}

            <div style={{width : "100%", display : 'flex', margin : '30px 0px'}}>
            <ContentsNavbar/>
            <div className="sub_contents">
                <div className="container px-3">
                  <h1 className="text-3xl font-bold">공정안전보고서(PSM)</h1>
                  <hr className="border-gray-700"/>

                  <div className="mt-8">
                    <h4>개요</h4>
                      <hr className="border-gray-700"/>
                      <h5>위험물질 누출, 화재, 폭발 등으로 인한 <strong>'중대산업사고'</strong>를 예방하기 위하여 공정안전보고서를 작성, 제출하여 심사, 확인을 받도록 한 제도</h5>
                      <span>[관련법규] <br/>산업안전보건법 제44조(공정안전보고서의 작성·제출), 산업안전보건법 제45조(공정안전보고서의 심사 등), 산업안전보건법 제46조(공정안전보고서의 이행 등),
                          산업안전보건법 시행령 제43조, 44조, 45조,
산업안전보건법 시행규칙 제50조, 51조, 52조, 53조, 54조</span>
                      <hr className="border-gray-700"/>
                      <h4>제출대상</h4>
                      <hr className="border-gray-700"/>
                      <h5>산업안전보건법 시행령 제43조(공정안전보고서의 제출 대상)에서 규정하는 대상 업종 사업장 또는 동 시행령 [별표13]에서 규정하고 있는
                      유해, 위험물질 중 하나 이상을 규정량 이상 제조, 취급, 저장하는 사업장</h5>
                      <h5 style={{color : "#1e90ff", fontWeight : "bold"}}>다음의 유해, 위험물질을 하루 동안 최대로 제조, 취급, 저장하는 사업장</h5>
                      <h5>※ 저장 용기에 보관 중인 유해, 위험물질을 공정에 그대로 투입하여 제조, 취급하는 경우, 저장량과 제조, 취급량 중 큰 값만을 적용하여(합산금지) 산정</h5>

                      <h4>※ 공정안전보고서 제출 대상 물질 51종</h4>
                      <table className="business_table2" style={{width : "100%"}}>
                          <colgroup>

                          </colgroup>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>번호</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>유해, 위험물질</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>기존</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>변경</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>번호</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>유해, 위험물질</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>기존</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>변경</th>
                            </tr>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>1</td>
                                    <td>인화성 가스*</td>
                                    <td>5,000</td>
                                    <td>5,000</td>
                                    <td>27</td>
                                    <td>브롬화수소</td>
                                    <td>2,500</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>2</td>
                                    <td>인화성 액체**</td>
                                    <td>5,000</td>
                                    <td>5,000</td>
                                    <td>28</td>
                                    <td>삼염화인</td>
                                    <td>750,000</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>3</td>
                                    <td>메틸 이소시아네이트</td>
                                    <td>150</td>
                                    <td>1,000</td>
                                    <td>29</td>
                                    <td>염화 벤질	</td>
                                    <td>750,000</td>
                                    <td>2,000</td>
                                </tr>
                            <tr>
                                    <td>4</td>
                                    <td>포스겐</td>
                                    <td>750</td>
                                    <td>500</td>
                                    <td>30</td>
                                    <td>이산화염소</td>
                                    <td>500</td>
                                    <td>500</td>
                                </tr>
                            <tr>
                                    <td>5</td>
                                    <td>아크릴로니트릴</td>
                                    <td>20,000</td>
                                    <td>10,000</td>
                                    <td>31</td>
                                    <td>염화 티오닐</td>
                                    <td>150</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>6</td>
                                    <td>암모니아</td>
                                    <td>200,000</td>
                                    <td>10,000</td>
                                    <td>32</td>
                                    <td>브롬</td>
                                    <td>100,000</td>
                                    <td>1,000</td>
                                </tr>
                            <tr>
                                    <td>7</td>
                                    <td>염소</td>
                                    <td>20,000</td>
                                    <td>1,500</td>
                                    <td>33</td>
                                    <td>일산화질소</td>
                                    <td>1,000</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>8</td>
                                    <td>이산화황</td>
                                    <td>250,000</td>
                                    <td>10,000</td>
                                    <td>34</td>
                                    <td>붕소 트리염화물</td>
                                    <td>1,500</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>9</td>
                                    <td>삼산화황</td>
                                    <td>75,000</td>
                                    <td>10,000</td>
                                    <td>35</td>
                                    <td>메틸에틸케톤과산화물</td>
                                    <td>2,500</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>10</td>
                                    <td>이황화탄소</td>
                                    <td>5,000</td>
                                    <td>10,000</td>
                                    <td>36</td>
                                    <td>삼불화 붕소</td>
                                    <td>150</td>
                                    <td>1,000</td>
                                </tr>
                            <tr>
                                    <td>11</td>
                                    <td>시안화수소</td>
                                    <td>1,000</td>
                                    <td>500</td>
                                    <td>37</td>
                                    <td>니트로아닐린</td>
                                    <td>2,500</td>
                                    <td>2,500</td>
                                </tr>
                            <tr>
                                    <td>12</td>
                                    <td>불화수소(무수불산)</td>
                                    <td>1,000</td>
                                    <td>1,000</td>
                                    <td>38</td>
                                    <td>염소 트리플루오르화</td>
                                    <td>500</td>
                                    <td>1,000</td>
                                </tr>
                            <tr>
                                    <td>13</td>
                                    <td>염화수소(무수염산)</td>
                                    <td>20,000</td>
                                    <td>10,000</td>
                                    <td>39</td>
                                    <td>불소</td>
                                    <td>20,000</td>
                                    <td>500</td>
                                </tr>
                            <tr>
                                    <td>14</td>
                                    <td>황화수소</td>
                                    <td>1,000</td>
                                    <td>1,000</td>
                                    <td>40</td>
                                    <td>시아누르 플루오르화물	</td>
                                    <td>50</td>
                                    <td>2,000</td>
                                </tr>
                            <tr>
                                    <td>15</td>
                                    <td>질산암모늄</td>
                                    <td>500,000</td>
                                    <td>500,000</td>
                                    <td>41</td>
                                    <td>질소 트리플루오르화물</td>
                                    <td>2,500</td>
                                    <td>20,000</td>
                                </tr>
                            <tr>
                                    <td>16</td>
                                    <td>니트로글리세린</td>
                                    <td>10,000</td>
                                    <td>10,000</td>
                                    <td>42</td>
                                    <td>니트로 셀롤로오스<br/>(질소 함유량 12.6% 이상)</td>
                                    <td>100,000</td>
                                    <td>100,000</td>
                                </tr>
                            <tr>
                                    <td>17</td>
                                    <td>트리니트로톨루엔</td>
                                    <td>50,000</td>
                                    <td>50,000</td>
                                    <td>43</td>
                                    <td>과산화벤조일</td>
                                    <td>3,500</td>
                                    <td>3,500</td>
                                </tr>
                            <tr>
                                    <td>18</td>
                                    <td>수소</td>
                                    <td>50,000</td>
                                    <td>5,000</td>
                                    <td>44</td>
                                    <td>과염소산 암모늄</td>
                                    <td>3,500</td>
                                    <td>3,500</td>
                                </tr>
                            <tr>
                                    <td>19</td>
                                    <td>산화에틸렌</td>
                                    <td>10,000</td>
                                    <td>1,000</td>
                                    <td>45</td>
                                    <td>디클로로실란</td>
                                    <td>1,500</td>
                                    <td>1,000</td>
                                </tr>
                            <tr>
                                    <td>20</td>
                                    <td>포스핀</td>
                                    <td>50</td>
                                    <td>500</td>
                                    <td>46</td>
                                    <td>디에틸 알루미늄 염화물</td>
                                    <td>2,500</td>
                                    <td>10,000</td>
                                </tr>
                            <tr>
                                    <td>21</td>
                                    <td>실란(Silane)</td>
                                    <td>50</td>
                                    <td>1,000</td>
                                    <td>47</td>
                                    <td>디이소프로필 퍼옥시디카보네이트</td>
                                    <td>3,500</td>
                                    <td>3,500</td>
                                </tr>
                            <tr>
                                    <td>22</td>
                                    <td>질산<br/>(중량 94.5% 이상)</td>
                                    <td>250</td>
                                    <td>50,000</td>
                                    <td>48</td>
                                    <td>불산<br/>(중량 1% 이상)</td>
                                    <td>1,000</td>
                                    <td>10,000<br/>(중량 10% 이상)</td>
                                </tr>
                            <tr>
                                    <td>23</td>
                                    <td>발연황산<br/>(삼산화황 중량 65% 이상 80% 미만)</td>
                                    <td>500,000</td>
                                    <td>20,000</td>
                                    <td>49</td>
                                    <td>염산<br/>(중량 10% 이상)</td>
                                    <td>20,000</td>
                                    <td>20,000<br/>(중량 20% 이상)</td>
                                </tr>
                            <tr>
                                    <td>24</td>
                                    <td>과산화수소<br/>(중량 52% 이상)</td>
                                    <td>3,500</td>
                                    <td>10,000</td>
                                    <td>50</td>
                                    <td>황산<br/>(중량 10% 이상)</td>
                                    <td>20,000</td>
                                    <td>20,000<br/>(중량 20% 이상)</td>
                                </tr>
                            <tr>
                                    <td>25</td>
                                    <td>톨루엔디이소시아네이트</td>
                                    <td>100,000</td>
                                    <td>2,000</td>
                                    <td>51</td>
                                    <td>암모니아수산<br/>(중량 10% 이상)</td>
                                    <td>20,000</td>
                                    <td>50,000<br/>(중량 20% 이상)</td>
                                </tr>
                            <tr>
                                    <td>26</td>
                                    <td>발연황산<br/>(삼산화황 중량 65% 이상 80% 미만)</td>
                                    <td>500,000</td>
                                    <td>10,000</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table><br/>
                      <h5>*인화성 가스: 인화한계 농도의 최저한도가 13% 이하 또는 최고한도와 최저한도의 차가 12% 이상인 것으로서 표준압력(101.3kPa)하의 20℃에서 가스 상태인 물질<br/>
※ 인화성 가스 중 도시가스(메탄 중량 85% 이상) : 취급 5,000(현행) → 50,000(개정)<br/>
   (사업장 외부로부터 배관을 통해 공급받아 최초 압력조정기 후단 이후의 압력이 0.1 MPa(계기압력) 미만으로 취급되는 사업장의 연료용으로 사용시 취급 시)<br/>
<br/>
**인화성 액체: 표준압력(101.3kPa)하에서 인화점이 60℃ 이하이거나 고온·고압의 공정운전조건으로 인하여 화재·폭발위험이 있는 상태에서 취급되는 가연성 물질<br/>

※ 산업안전보건법 시행령 별표 13(유해위험물질 규정량)의 개정규정은 다음 각 호의 구분에 따른 날부터 적용<br/>

    1. 상시근로자 5명 이상을 사용하는 사업장: 2021년 1월 16일<br/>

    2. 상시근로자 5명 미만을 사용하는 사업장: 2021년 7월 16일</h5>
                      <h4 >※ 대상 업종</h4>
                      <table className="business_table2" style={{width : "100%"}}>
                          <colgroup>
                              <col width="30%"/>
                              <col width="20%"/>
                              <col width="30%"/>
                              <col width="20%"/>
                          </colgroup>
                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>업종</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>업종분류코드</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>업종</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>업종분류코드</th>
                            </tr>
                            <tbody style={{textAlign : "center"}}>
                                <tr>
                                    <td>원유 정제처리업</td>
                                    <td>19210</td>
                                    <td>질소 화합물, 질소·인산 및 칼리질 화학비료 제조업 중 질소질 화학비료 제조업</td>
                                    <td>20311</td>
                                </tr>
                            <tr>
                                    <td>기타 석유정제물 재처리업	</td>
                                    <td>19229</td>
                                    <td>복합비료 및 기타 화학비료 제조업 중 복합비료 제조업(단순혼합 또는 배합에 의한 경우는 제외)	</td>
                                    <td>20312</td>
                                </tr>
                            <tr>
                                    <td>석유화학계 기초화학물질 제조업	</td>
                                    <td>20111</td>
                                    <td>화학 살균·살충제 및 농업용 약제 제조업(농약 원제 제조만 해당)	</td>
                                    <td>20321</td>
                                </tr>
                            <tr>
                                    <td>합성수지 및 기타 플라스틱물질 제조업	</td>
                                    <td>20202</td>
                                    <td>화약 및 불꽃제품 제조업	</td>
                                    <td>20494</td>
                                </tr>

                            </tbody>
                        </table>
                      <br/>
                      <h5>※ 합성수지 및 기타 플라스틱물질 제조업은 별표 13의 제1호 또는 제2호에 해당하는 경우로 한정</h5>
                      <hr className="border-gray-700"/>
                      <h4>제출 대상, 시기, 서류</h4>
                      <hr className="border-gray-700"/>
                      <h5>제출 주체 : 공정안전보고서 제출 대상 사업장의 사업주</h5>
                      <h5>제출 시기 : 착공일 30일 전 (설치, 이전 / 주요 구조부분의 변경)</h5>
                      <h5>※ 기존 설비의 제조, 취급, 저장 물질 변경 / 제조량, 취급량, 저장량이 증가하여 규정량에 해당하게 된 때에는 그 해당일 30일전</h5>
                      <h5>제출 서류 : 공정안전보고서 심사신청서(고용노동부 고시 제2020-55호 별지 1호 서식),  공정안전보고서 2부</h5>
                      <hr className="border-gray-700"/>
                      <h4>공정안전보고서 내용</h4><span>[산업안전보건법 시행규칙 제50조(공정안전보고서의 세부 내용 등)]</span>
                      <table className="business_table2" style={{width : "100%", marginTop : "30px"}}>

                            <tr>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>구분</th>
                                <th style={{color : "white", textAlign : "center", height : "35px", "background" : "#1e90ff"}}>내용</th>

                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{textAlign : "center"}}>공정안전자료</td>
                                    <td>-  취급·저장하고 있는 유해·위험물질의 종류 및 수량<br/>
-  유해·위험물질에 대한 물질안전보건자료(MSDS)<br/>
-  유해·위험설비의 목록 및 사양<br/>
-  유해·위험설비의 운전방법을 알 수 있는 공정도면<br/>
-  각종 건물·설비의 배치도<br/>
-  폭발위험장소 구분도 및 전기단선도<br/>
-  위험설비의 안전설계·제작 및 설치 관련 지침서</td>
                                </tr>
                            <tr>
                                    <td style={{textAlign : "center"}}>공정위험성평가</td>
                                    <td>ㅇ공정의 특성 등을 고려한 위험성평가 기법 선정<br/>

-  위험성 평가<br/>
-  평가결과 개선계획 수립<br/>
-  피해범위 산정 및 영향평가<br/>
-  사고예방·피해 최소화 대책 작성</td>
                                </tr>
                            <tr>
                                    <td style={{textAlign : "center"}}>안전운전계획</td>
                                    <td>-  안전운전지침서<br/>
-  설비점검·검사 및 보수계획, 유지계획 및 지침서<br/>
-  안전작업허가<br/>
-  도급업체 안전관리계획<br/>
-  근로자 등 교육계획<br/>
-  가동 전 점검지침<br/>
-  변경요소 관리계획<br/>
-  자체 감사 및 사고조사계획<br/>
-  그 밖에 안전운전에 필요한 사항</td>
                                </tr>
                            <tr>
                                    <td style={{textAlign : "center"}}>비상조치계획</td>
                                    <td>-  비상조치를 위한 장비·인력보유현황<br/>
-  사고 발생 시 각 부서·관련 기관과의 비상연락체계<br/>
-  사고 발생 시 비상조치를 위한 조직의 임무 및 수행 절차<br/>
-  비상조치계획에 따른 교육계획<br/>
-  주민홍보계획<br/>
-  그 밖에 비상조치 관련 사항</td>
                                </tr>

                            </tbody>
                        </table>
                  </div>
                </div>
            </div>
      </div>
      </div>
  )
}
export default PSM
