import React from "react";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const Pagination = ({currentPage, postsPerPage, totalPosts, paginate }) => {
  const pageNumber = [];
  const history = useHistory();
  // Math.ceil: 올림
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumber.push(i);
  }

  return (
    <div className="h_pager" style={{display : "flex", justifyContent : "space-between"}}>
        <ul>
          {pageNumber.map((pageNum) => (

            <li
              key={pageNum}
              className={pageNum === currentPage ? "on" : ""}
              onClick={() => paginate(pageNum)}
            >
              {pageNum}
            </li>

          ))}
        </ul>
        <Button onClick={() => {history.push('/admin/typography')}} style={{width : "80px", height : "40px", margin : "auto 0"}}>글쓰기</Button>
    </div>
  );
};

export default Pagination;