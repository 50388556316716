import React, { useState } from 'react'
import {Link} from "react-router-dom";
import org from "../../../assets/img/organization.png";
import "./org.css"

export default function Organization() {

  return (
      <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner">
          <h2 className="tit">조직도</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/intro">회사소개</Link></li>
            <li><Link to="/intro/organization">조직도</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox greeting">
            <div>
              <h3 style={{fontWeight : "bold"}}>회사 조직도</h3>
              <hr/>
              <div>
                <img style={{margin: "auto", display: "block"}} src={org}/>
              </div>
            </div>
        </div>
      </div>
  );
}
