import React, {useState} from 'react';

import {Accordion, Button, Card, Col, Container, Form, Row} from "react-bootstrap";

import {check_params, parse_krw} from "../../../utils";
import contract_api from '../../../api/ContractAPI';
import ContractMakeForm from "../../../components/Contract/ContractMakeForm";
import {useHistory} from "react-router-dom";
import axios from "axios";

const ContractMake = () => {
  const server = "http://18.179.138.213:3001"
const check_number_value = ["technical_price", "construction_price", "accrued_price"]
    const history = useHistory();
  const [inputs, setInputs] = useState({
    company : '',
    agent : '',
    orderer : '',
    office_address : '',
    construction_name : '',
    industrial_number : '',
    business_number : '',
    location : '',
    phone : '',
    orderer_address : '',
    term_start : '',
    term_end : '',
    construction_start : '',
    construction_end : '',
    technical_class : 'Construction',
    technical_price : "",
    technical_number : 0,
    construction_price: "",
    accrued_price: "",
    contract_date: "",
    remark : "",
    construction_phone : "",
    username : ""
  })

  const {company, agent, orderer, office_address, construction_name, industrial_number, business_number, location, phone, orderer_address, term_start, term_end,
  construction_start, construction_end, technical_class, technical_price, technical_number, construction_phone, construction_price, accrued_price, contract_date, remark} = inputs

  const handleSave = async () => {
    if (contract_date === "" || construction_name === "" || agent === "" || company === "" || location === "" || construction_price === 0 || orderer === "" ||
    construction_start === "" || construction_end === "" || term_end === "" || term_start === "" || technical_class === "" || technical_number === 0 || technical_price === 0) {
      return alert("필수 항목을 입력하시오.")
    }
    let post_data = make_post();
    await axios.post(server + "/createcontract", post_data).then(response => {
      let response_data = response.data
      if (response_data['response'] == 200 ) {
        alert('저장되었습니다.')
        history.push("/admin/contract/" + response_data['id'])
      }else {
        alert('저장에 실패하였습니다.')
      }
    }).catch( err => {
      alert('오류가 발생하였습니다.' + err)
    })


  }

  const handleChange = (e) => {
    let {value, name} = e.target
    check_number_value.map(check_name => {
      if (check_name == name) {
        value = parse_krw(value)
      }
    })

    setInputs({
      ...inputs,
      [name] : value
    })
  }
  const handleSetData = (e) => {
    e.preventDefault()
    setInputs({
      ...inputs,
      term_start: construction_start,
      term_end: construction_end
    })
  }
    return (
        <>

      <Container fluid>
        <Row>
          <Col md="12">
            <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0" style={{justifyContent : "space-between", padding : "15px"}}>
                <Card.Title as="h4" >기술지도 계약서</Card.Title>

                </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <ContractMakeForm
                  inputs={inputs}
                  handleSave={handleSave}
                  handleChange={handleChange}
                  handleSetData={handleSetData}
                />
              </Accordion.Collapse>
              </Card>
            </Accordion>
            {/*
            <Accordion>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1" style={{justifyContent : "space-between", padding : "15px"}}>
                <Card.Title as="h4" >엑셀 업로드</Card.Title>

                </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <ContractExcelForm/>
              </Accordion.Collapse>
              </Card>
            </Accordion>
            */}
          </Col>

        </Row>
      </Container>
    </>
    )

    function make_post() {
        try{
          let token = sessionStorage.getItem('token')
          let user_id = sessionStorage.getItem('id')
          let userphone = sessionStorage.getItem('userphone')
          let username = sessionStorage.getItem('username')
          let usernumber = sessionStorage.getItem('usernumber')
          username = username + " (" + userphone + ")"
          let _inputs = inputs;
          _inputs.username = username;

          let post_data = {
              "token" : token,
              "user_id" : user_id,
              "contract" : _inputs,
              "usernumber" : usernumber
          }
          return post_data
        }catch (e) {
          return {}
        }

    }
}
export default ContractMake;
