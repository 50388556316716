import axios from 'axios'

const server = "http://18.179.138.213:3001"
const token = sessionStorage.getItem('token')
const user_id = sessionStorage.getItem('id')

const login = (email, password) => {
  let data = JSON.stringify({"email" : email, "password" : password})
  return axios.post(server, data)
}

const all_users = async () => {
  let data = JSON.stringify({"token" : token, "user_id" : user_id})
  return await axios.post(server + "/allusers", data)
}

const find_user = async (target_id) => {
  let data = JSON.stringify({"token" : token, "user_id" : user_id, "target_id" : target_id})
  return await axios.post(server + "/finduser", data)
}

const update_user = async (target_id, userphone, username, usernumber, access) => {
  let data = JSON.stringify({"token" : token, "user_id" : user_id, "target_id" : target_id, "userphone" : userphone, "username" : username, "usernumber" : usernumber, "access" : access})

  return await axios.post(server + "/updateuser", data)

}

const get_contacts = async () => {
  let data = JSON.stringify({"token" : token})
  return await axios.post(server + "/contacts", data)

}

const update_contact = async (contact_id, status, remark) => {
    let data = JSON.stringify({"token" : token, "contact_id" : contact_id, "status" : status, "remark" : remark})

   return await axios.post(server + "/updatecontact", data)
}

const dash = async () => {
    let data = JSON.stringify({"token" : token, "user_id" : user_id})

   return await axios.post(server + "/dash", data)
}

export default {
  login,
  all_users,
  find_user,
  update_user,
  get_contacts,
  update_contact,
  dash,

}
