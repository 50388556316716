export function parse_krw(krw){
    krw = krw.toString().replace(/,/gi, "")
    return krw.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function check_params(id){
    try {
      let parse_id = id.replace(/[^0-9]/g,'')
      parse_id = Number(parse_id)
      return parse_id
    } catch (e){
      return -1
    }

}

