import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import bg from "../../assets/img/bg3.jpg"
import axios from 'axios'
const Contact = () => {
  const [inputs, setInputs] = useState({
    "name" : "",
    "email" : "",
    "phone" : "",
    "message" : ""
  })

  const { name, email, phone, message } = inputs

  const handleChange = (e) => {
    let {value, name} = e.target
    setInputs({
      ...inputs,
      [name] : value
    })
  }
  const handleSave = () => {
    if (name === "" || email === "" || phone === "" || message === "") {
      return alert("빈칸을 입력하여주십시오.")
    }
    alert("빠른 시일내의 답변드리겠습니다. 감사합니다.");
    setInputs({
      "name" : "",
      "email" : "",
      "phone" : "",
      "message" : ""
    })
    return;
    axios.post("http://18.179.138.213:3001/contact", inputs).then(response => {
      if (response.data.response == 200) {
        alert("빠른 시일내의 답변드리겠습니다. 감사합니다.")
        setInputs({
          "name" : "",
          "email" : "",
          "phone" : "",
          "message" : ""
        })
      }else {
        alert("잠시후 다시 시도 바랍니다.")
      }
    }).catch(err => {
      alert("네트워크 오류 : \n" + err)
    })
  }
    return (
        <div className="sub_container">
        {/*-- 타이틀 --*/}
        <div className="sub_title_banner" style={{background: `url(${bg}) center center no-repeat`}}>
          <h2 className="tit">문의하기</h2>
        </div>
        {/*--// 타이틀 --*/}
        {/*-- 리본바 --*/}
        <div className="sub_reborn">
          <ul>
            <li><Link to="/">홈</Link></li>
            <li><Link to="/contact">문의하기</Link></li>
          </ul>
        </div>
        {/*--// 리본바 --*/}
        <div className="sub_conbox ask">
          <div className="infobox">
            <ul>
              <li>
                <span className="tit">TEL</span>
                <span>031-405-7833</span>
              </li>
              <li>
                <span className="tit">FAX</span>
                <span>031-405-7835</span>
              </li>
              <li>
                <span className="tit">Email</span>
                <span>4057833@hanmail.net</span>
              </li>
            </ul>
          </div>
          <div className="askbox">
            <h2>문의하기</h2>
            <p>
              방문해주셔서 감사합니다.<br />
              궁금하신점은 언제든지 문의부탁드립니다.
            </p>
            <div className="form">
              <ul>
                <li>
                  <label htmlFor="f1">이름</label>
                  <input id="f1" type="text" className="ask_input" placeholder="OOO"
                    name="name"
                        value={name}
                        onChange={handleChange}
                  />
                </li>
                <li>
                  <label htmlFor="f2">이메일</label>
                  <input id="f2" type="text" className="ask_input" placeholder="email@email.com"
                    name="email"
                        value={email}
                        onChange={handleChange}
                  />
                </li>
                <li>
                  <label htmlFor="f3">연락처</label>
                  <input id="f3" type="text" className="ask_input" placeholder="010-****-****"
                  name="phone"
                        value={phone}
                        onChange={handleChange}
                  />
                </li>
              </ul>
              <label htmlFor="f4">문의내용</label>
              <textarea id="f4" cols={30} rows={8} placeholder="무엇이 궁금하신가요?"
                        name="message"
                        value={message}
                        onChange={handleChange}/>
            </div>
            <button type="button" className="ask_btn" onClick={handleSave}>보내기</button>
          </div>
        </div>
      </div>
    )
}
export default Contact;
