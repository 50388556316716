import React, {useState, useEffect} from 'react';
import '../../assets/css/auth.css'
import {Link} from "react-router-dom";
import axios from 'axios'

const Register = () => {
const server = "http://18.179.138.213:3001"
  const [inputs, setInputs] = useState({
    username : "",
    email : "",
    password : "",
    check_password : ""
  })
  const [inputValue, setInputValue] = useState("")

  let {username, email, password, check_password, user_phone} = inputs
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  const handleChange = (e) => {
    let {value, name} = e.target

    setInputs({
      ...inputs,
      [name] : value
    })
  }

  const handleRegister = async (e) => {
    // 체크 해야할 것
    // 1. 이메일 형식
    // 2. 패스워스 동일 및 길이
    // 3. 빈칸
    e.preventDefault()
    if ( username === "" ||  email === "" || password === "" || check_password === "" || user_phone){
      return alert("빈칸을 입력해주십시오.")
    }

    if ( password.length < 6 || check_password < 6){
      return alert("비밀번호는 6자리 이상입니다.")
    }
    if ( password !== check_password) {
      return alert("비밀번호를 올바르게 입력하시오.")
    }

    if (!re.test(email)){
      return alert("이메일 형식을 올바르게 입력하시오.")
    }

    if (inputValue.length != 13) {
      return alert("휴대폰 번호를 제대로 입력하시오. \nex)010-1234-5678")
    }

    let post_datas = JSON.stringify({"userEmail" : email, "password" : password, "name" : username, "phone" : inputValue})
    await axios.post(server + "/register", post_datas, {headers: {
      'Content-Type': 'application/json'
      }}).then((response) => {
      if (response.data.code == 404){
        return alert("중복된 이메일입니다.")
      }else if (response.data.code == 200) {
        alert("회원가입 성공")
        return window.location.replace("/login")
      }
    }).catch((err) => {
      alert("이미 존재하는 이메일입니다.")
    })

  }
  const handlePhone = (e) => {
    setInputValue(e.target.value)
  }
  useEffect(() => {
    if (inputValue.length === 10) {
      setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (inputValue.length === 13) {
      setInputValue(inputValue.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  }, [inputValue]);
    return (
        <div className="login_div">
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <Link to="/login"><h2 className="inactive underlineHover"> 로그인 </h2></Link>
                    <h2 className="active">회원가입 </h2>
                    <form>
                        <input type="text" id="name" className="fadeIn third" name="username"
                                   placeholder="성명" value={username} onChange={handleChange}/>
                        <input type="text" id="login" className="fadeIn second" name="email"
                               placeholder="이메일" value={email} onChange={handleChange}/>
                            <input type="password" id="password" className="fadeIn third" name="password"
                                   placeholder="비밀번호" value={password} onChange={handleChange}/>
                        <input type="password" id="password" className="fadeIn third" name="check_password"
                                   placeholder="비밀번호 확인" value={check_password} onChange={handleChange}/>
                        <input type="text" id="phone" className="fadeIn third" name="phone"
                                   placeholder="전화번호 010-0000-0000" value={inputValue} onChange={handlePhone}/>
                        <input type="submit" className="fadeIn fourth" value="회원가입" onClick={handleRegister}/>
                    </form>

                    <div id="formFooter">

                    </div>

                </div>
            </div>
        </div>
    )
}
export default Register;
